import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';

const ChangeRegistrationType = ({ details, onSubmit, handleClose }) => {
    return (
        <Row className="mx-auto w-100 g-0">
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mb-3'>
                <p className='mb-0'>Are you sure you want to mark attendee as <b className="bold">{details.registrationType === "Registered" ? "Walkin" : "Registered"}</b>?
                </p>
            </Col>

            <Col className='d-flex align-items-center justify-content-end'>
                <Button className="modalButton lightGray me-3" onClick={handleClose} >
                    CLOSE
                </Button>
                <Button className="modalButton pink me-3" onClick={(e) => {
                    handleClose(e); onSubmit(details)
                }}>
                    YES
                </Button>
            </Col>
        </Row>
    )
}

export default ChangeRegistrationType