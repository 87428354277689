import React, { useState, useContext } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { titleOfBreakout, titleOfDay } from '../../../../helper/commonHelper';
import { AttendeeContext } from '../../../../context/AttendeeContext';
import "../home.scss"
import { FiguresContext } from '../../../../context/FiguresContext';
const EventInfo = ({ data }) => {
  const [show, setShow] = useState(false);
  let activeDay = localStorage.getItem("activeDay");
  let activeBreakout = localStorage.getItem("activeBreakout");
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const { figures, setFigures } = useContext(FiguresContext);
  function groupByDayAndTitle(data) {
    const groupedData = {};

    data.forEach(item => {
      // Check if the day already exists in the groupedData object
      if (!groupedData[item.day]) {
        groupedData[item.day] = {};
      }

      // Find or add the title to the day's group
      let titlesGroup = groupedData[item.day];
      if (!titlesGroup[item.title]) {
        titlesGroup[item.title] = [];
      }

      // Add the item to the corresponding title group
      titlesGroup[item.title].push(item);
    });

    return groupedData;
  }

  const event = data && data.event ? data.event : undefined;
  let breakoutByGroup = null;
  if (eventData) {
    if (eventData.eventBreakouts) {
      breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
    }
  }
  const fullDisplay = (eventData && eventData.eventDays && eventData.eventDays.length > 1 || (breakoutByGroup && breakoutByGroup[1]))
  return (<>
    {event != undefined && data &&
      <Row className='eventInfoRow g-0 mx-auto'>
        <Col xl={fullDisplay ? 8 : 12} lg={fullDisplay ? 8 : 12} md={fullDisplay ? 6 : 12} sm={12} xs={12} >
          <div className='eventName'>
            <h3>{event.title}</h3>

          </div>
        </Col>
        <Col xl={fullDisplay ? 4 : 0} lg={fullDisplay ? 4 : 0} md={fullDisplay ? 6 : 0} sm={fullDisplay ? 12 : 0} xs={fullDisplay ? 12 : 0}>
          <div className='daysAndBreakout'>
            {fullDisplay &&
              <button title='Click to select session' className='btn-clickexpand selectDayBreakoutIcon' onClick={() => setShow(!show)} />}
            {/* <p>Breakout 1 <span>(Day 1)</span></p> */}

          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='eventNameCol'>
          <div className='eventName'>
            <p className='mb-0'>{event.location && event.location.country} <span className='mx-2'>|</span> {event.formattedDate}</p>
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className=''>
          <div className='daysAndBreakoutValue'>
            {(attendee.activeBreakout != null) ? <p>{titleOfBreakout(activeBreakout)}<span className='ms-1'>({titleOfDay(activeDay)})</span></p> : <p>{titleOfDay(activeDay)}</p>}
          </div>
        </Col>


        <div className={`selectionList  ${show ? "selectionListDisplay" : "selectionListHide"}`}>
          <Row className='w-100 mx-auto '>
            {eventData && eventData.eventDays && eventData.eventDays.length > 0 && eventData.eventDays.map((day, i) => {
              return (
                <React.Fragment key={i}>
                  <Col xl={4} lg={4} md={fullDisplay ? 4 : 12} sm={12} xs={12} className='mb-3' >
                    <div className={`${attendee.activeBreakout != null ? "hideInnerColor" : ""} ${parseInt(attendee.activeDay) === parseInt(day.day) ? "active" : ""} customRadioHolder big`} onClick={(e) => {
                      setAttendee((prevState) => ({
                        ...prevState,
                        activeDay: parseInt(day.day),
                        activeBreakout: null,
                      }))
                      setFigures((prevState) => ({
                        ...prevState,
                        activeDay: parseInt(day.day),
                        activeBreakout: null,
                      }))
                      localStorage.setItem("activeDay", day.day);
                      localStorage.setItem("figuresActiveDay", day.day);
                      localStorage.setItem("activeBreakout", null);
                      localStorage.setItem("figuresActiveBreakout", null);
                    }}>
                      <div className={`${parseInt(attendee.activeDay) === parseInt(day.day) ? "active" : ""} customRadioButton`}>
                        {parseInt(attendee.activeDay) === parseInt(day.day) &&
                          <div className="customRadioButtonActive">
                          </div>}
                      </div>
                      <p className="customRadioLabel mb-0">{day.title}</p>
                    </div>

                  </Col >

                </React.Fragment>
              )

            })}
            {Object.entries(breakoutByGroup).map(([day, titles]) => (
              <>
                {parseInt(activeDay) === parseInt(day) &&
                  <div key={day} >
                    {Object.entries(titles).map(([title, items]) => (
                      <div className="alertBox mt-1 mx-xl-4 mx-lg-4 mx-1">
                        <div className='alertHead pb-2 mb-1'>
                          {title}
                        </div>
                        {items.map((session, sessionIndex) => (
                          <div key={sessionIndex} className="mt-2">
                            <Col xl={12} lg={12} md={12} className='ms-xl-4 ms-md-2 ms-2'>
                              <div className={`${attendee.activeBreakout === session._id ? "active" : ""} customRadioHolder`} onClick={(e) => {
                                setAttendee((prevState) => ({
                                  ...prevState,
                                  activeBreakout: session._id,
                                }))
                                setFigures((prevState) => ({
                                  ...prevState,
                                  activeBreakout: session._id,
                                }))
                                localStorage.setItem("activeBreakout", session._id);
                                localStorage.setItem("figuresActiveBreakout", session._id);
                              }}>
                                <div className={`${attendee.activeBreakout === session._id ? "active" : ""} customRadioButton`}>
                                  {attendee.activeBreakout === session._id &&
                                    <div className="customRadioButtonActive">
                                    </div>}
                                </div>
                                <p className="customRadioLabel mb-0">{session.sessionTitle}</p>
                              </div>

                            </Col >
                          </div>
                        ))}
                      </div>

                    ))}
                  </div>}
              </>
            ))}



            {/* {eventData && eventData.eventBreakouts && eventData.eventBreakouts.length > 0 &&
              <div className="alertBox mt-2">
                <div className='alertHead pb-2 mb-3'>
                  {eventData.eventBreakouts && eventData.eventBreakouts[0].title}
                </div>
                {eventData && eventData.eventBreakouts && eventData.eventBreakouts.map((breakout, i) => {
                  if (parseInt(activeDay) === breakout.day) return (
                    <div key={i} className="mt-2">
                      <Col xl={12} lg={12} md={12} className='ms-xl-4 ms-md-2 ms-2'>
                        <div className={`${attendee.activeBreakout === breakout._id ? "active" : ""} customRadioHolder`} onClick={(e) => {
                          setAttendee((prevState) => ({
                            ...prevState,
                            activeBreakout: breakout._id,
                          }))
                          localStorage.setItem("activeBreakout", breakout._id);
                        }}>
                          <div className={`${attendee.activeBreakout === breakout._id ? "active" : ""} customRadioButton`}>
                            {attendee.activeBreakout === breakout._id &&
                              <div className="customRadioButtonActive">
                              </div>}
                          </div>
                          <p className="customRadioLabel mb-0">{breakout.sessionTitle}</p>
                        </div>

                      </Col >
                    </div>
                  )
                })}
              </div>} */}

          </Row>

        </div>


      </Row >}
  </>

  )
}

export default EventInfo