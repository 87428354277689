import { updatedAttendeesList } from "./sideHeaderHelper";

export const getFilteredAttendees = (filters) => {
    const attendees = updatedAttendeesList();
    return attendees && attendees.filter(attendee => {
        // Check if checkin matches the filters (if provided)
        if (filters.checkin !== undefined && filters.checkin !== "" && attendee.checkin !== filters.checkin) {
            return false;
        }

        // Check if peopleGroup matches the filters (if provided)
        if (filters.peopleGroup !== undefined && filters.peopleGroup !== "" && attendee.peopleGroup !== filters.peopleGroup) {
            return false;
        }
        if (filters.country !== undefined && filters.country !== "" && attendee.country !== filters.country) {
            return false;
        }
        if (filters.sector !== undefined && filters.sector !== "" && attendee.sector !== filters.sector) {
            return false;
        }
        // Check if registrationType matches the filters (if provided)
        if (filters.registrationType !== undefined && filters.registrationType !== "" && attendee.registrationType !== filters.registrationType) {
            return false;
        }

        // All filters match, return true to keep this attendee
        return true;
    });
};