import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useReducer,
} from "react";
import Filters from "./Filters";
import "./attendeesList.scss";
import SearchArea from "./SearchArea";
import {
  getAvailableCountries,
  getAvailableSectors,
  getCheckinValue,
  getClientName,
  getColorOfPeopleGroup,
  headerItems,
  highestIdObject,
  removeAttendeeFromData,
  removeObjectFromData,
  removedObjectFromData,
} from "../../../../../helper/sideHeaderHelper";
import { AttendeeContext } from "../../../../../context/AttendeeContext";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import axios from "../../../../../config/axios";
import { Modal, Button } from "react-bootstrap";
import {
  getAttendeeInfoMissing,
  getPrintBadgeValue,
} from "../../../../../helper/commonHelper";
import { Scrollbars } from "react-custom-scrollbars";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import RemoveCustomFlag from "./RemoveCustomFlag";
import {
  updateInitialState,
  updateReducer,
} from "../../../../../reducers/updateReducer";
import AttendeeName from "./AttendeeName";
import { AuthContext } from "../../../../../context/AuthContext";
import useApiPrintAction from "../../../../UI/shared/addToDownload/PrintBadgeAction";
import { checkPermission } from "../../../../../helper/permissionsHelper";
const AttendeesList = () => {
  const { attendee, setAttendee } = useContext(AttendeeContext);
  const { layout, setLayout } = useContext(HomeScreenContext);
  const { userAuthState } = useContext(AuthContext);
  let attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const user = JSON.parse(localStorage.getItem("user"));

  const [state, setState] = useState({
    loading: false,
    show: false,
    delete: false,
    registrationType: "",
    checkBoxLoading: false,
    checkInAttendee: null,
    showMessage: false,
    actionMessage: null,
    deleteValues: {},
    checkOutValues: {},
  });
  const { PrintBadgeAction } = useApiPrintAction();
  const [show, setShow] = useState({
    flagShow: false,
    item: null,
  });
  const [flagState, flagDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const handleFlagClose = () =>
    setShow((prevState) => ({
      ...prevState,
      flagShow: false,
      item: null,
    }));
  const handleFlagShow = (data) =>
    setShow((prevState) => ({
      ...prevState,
      flagShow: true,
      item: data,
    }));
  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
  }, [setState]);
  const handleShow = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      show: true,
    }));
  }, [setState]);

  const deleteAttendee = async (values) => {
    let attendeesContentOld = JSON.parse(localStorage.getItem("attendeesContent"));
    const updatedUpdates = {
      ...attendeesContent,
      attendees: removeAttendeeFromData(values),
      personActions: removeObjectFromData(values),
    };
    setState((prevState) => ({
      ...prevState,
      checkBoxLoading: true,
      checkInAttendee: values.attendeeId,
    }));

    setAttendee((prevState) => ({
      ...prevState,
      attendees: updatedUpdates.attendee,
    }));
    const dataToRemove = removedObjectFromData(values);
    localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
    try {
      const updateResponse = await axios.post(`check-in/${user.activityId}`, {
        ...dataToRemove,
        undo: 1,
        attendeeId: values.attendeeId,
        performerId: user._id,
        performerType: user.type,
        personName: user.name,
        appType: "Web",
      });
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        setAttendee((prevState) => ({
          ...prevState,
          attendees: updatedUpdates.attendee,
          personActions: updatedUpdates.personActions,
        }));
        setState((prevState) => ({
          ...prevState,
          delete: false,
          deleteValues: {},
          checkBoxLoading: false,
          checkInAttendee: null,
          showMessage: true,
          actionMessage: "Checkout Successful",
        }));
        localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
      }
      else {
        throw new Error("Failed to update updates object");
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,

        showMessage: true,
        actionMessage: "Checkout Error",
      }));
      localStorage.setItem("attendeesContent", JSON.stringify(attendeesContentOld));
      setAttendee((prevState) => ({
        ...prevState,
        attendees: attendeesContentOld.attendee,
        personActions: attendeesContentOld.personActions,
      }));
      console.error("There was a problem with the delete operation:", error);
    }
  };
  const checkOutAttendee = async (values) => {
    let attendeesContentOld = JSON.parse(localStorage.getItem("attendeesContent"));

    const updatedUpdates = {
      ...attendeesContent,
      personActions: removeObjectFromData(values),
    };
    setState((prevState) => ({
      ...prevState,
      checkBoxLoading: true,
      checkInAttendee: values.attendeeId,
    }));
    setAttendee((prevState) => ({
      ...prevState,
      attendees: updatedUpdates.attendee,
    }));
    const dataToRemove = removedObjectFromData(values);
    localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
    const newData = {
      ...dataToRemove,
      undo: 1,
      attendeeId: values.attendeeId,
      performerId: user._id,
      performerType: user.type,
      personName: user.name,
      appType: "Web",
    };
    try {
      const updateResponse = await axios.post(`check-in/${user.activityId}`, {
        ...newData,
      });
      if (updateResponse.status >= 200 && updateResponse.status < 300) {
        setAttendee((prevState) => ({
          ...prevState,
          personActions: updatedUpdates.personActions,
        }));
        setState((prevState) => ({
          ...prevState,
          delete: false,
          checkOutValues: {},
          checkBoxLoading: false,
          checkInAttendee: null,
          showMessage: true,
          actionMessage: "Checkout Successful",
        }));
        console.log("updated----------->", updatedUpdates.personActions, "Old----------------------->", attendeesContentOld.personActions)
      }
      else {
        throw new Error("Failed to update updates object");
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        showMessage: true,
        actionMessage: "Checkout Error",
      }));
      console.log("updated----------->", updatedUpdates.personActions, "Old----------------------->", attendeesContentOld.personActions)
      localStorage.setItem("attendeesContent", JSON.stringify(attendeesContentOld));
      setAttendee((prevState) => ({
        ...prevState,
        attendees: attendeesContentOld.attendee,
        personActions: attendeesContentOld.personActions,
      }));
      console.log("There was a problem with the delete operation:", error);
    }
  };
  const onSubmit = async (data) => {
    const values = {
      activityId: user.activityId,
      dayId: parseInt(attendee.activeDay),
      breakoutId:
        attendee.activeBreakout === null || attendee.activeBreakout === "null"
          ? null
          : attendee.activeBreakout,
      attendeeId: data._id,
      undo: getCheckinValue(data._id),
    };

    if (values.undo === 1 || values.undo === "1") {
      console.log(data)
      if (data.registrationType === "Registered") {
        setState((prevState) => ({
          ...prevState,
          checkOutValues: values,
          registrationType: data.registrationType,
        }));
        handleShow();
      } else if (data.registrationType === "Walkin") {
        setState((prevState) => ({
          ...prevState,
          deleteValues: values,
          registrationType: data.registrationType,
        }));
        handleShow();
      }
    } else if (values.undo === 0) {
      let attendeesContentOld = JSON.parse(localStorage.getItem("attendeesContent"));
      var today = new Date();
      var hours = String(today.getHours()).padStart(2, "0"); // Ensures hours are always two digits
      var minutes = String(today.getMinutes()).padStart(2, "0"); // Ensures minutes are always two digits
      var time = hours + ":" + minutes;
      const newData = {
        undo: values.undo === 1 ? true : false,
        id: highestIdObject() + 1,
        attendeeId: values.attendeeId,
        activityId: user.activityId,
        dayId: attendee.activeDay,
        breakoutId:
          attendee.activeBreakout === null || attendee.activeBreakout === "null"
            ? null
            : attendee.activeBreakout,
        personId: data._id,
        checkin: "Yes",
        checkinTime: time,
        performerId: userAuthState.user && userAuthState.user._id,
        performerType: userAuthState.user && userAuthState.user.type,
        personName: userAuthState.user && userAuthState.user.name,
        appType: "Web",
      };
      attendeesContent.personActions.push(newData);
      const updatedUpdates = { ...attendeesContent };
      setState((prevState) => ({
        ...prevState,
        checkBoxLoading: true,
        checkInAttendee: values.attendeeId,
      }));
      localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));

      setAttendee((prevState) => ({
        ...prevState,
        attendees: updatedUpdates.attendee,
        personActions: updatedUpdates.personActions,
      }));

      try {
        const updateResponse = await axios.post(
          `check-in/${user.activityId}`,
          newData
        );
        if (updateResponse.status >= 200 && updateResponse.status < 300) {
          setState((prevState) => ({
            ...prevState,
            checkBoxLoading: false,
            checkInAttendee: null,
            showMessage: true,
            actionMessage: "Check-in Successful",
          }));
          localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
        }
        else {
          throw new Error("Failed to update updates object");
        }
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          showMessage: true,
          actionMessage: "Check-in Error",
        }));
        localStorage.setItem("attendeesContent", JSON.stringify(attendeesContentOld));
        setAttendee((prevState) => ({
          ...prevState,
          attendees: attendeesContentOld.attendee,
          personActions: attendeesContentOld.personActions,
        }));
        console.error("There was a problem with the delete operation:", error);
      }
    }
  };
  const handleDoubleClick = useCallback(
    (attendee) => {
      if (layout.isServerBusy) {
        setTimeout(() => {
          onSubmit(attendee);
        }, 1000);
      } else {
        onSubmit(attendee);
      }
    },
    [layout.isServerBusy, onSubmit, attendee]
  );
  useEffect(() => {
    setAttendee((prevState) => ({
      ...prevState,
      activeAttendeeDetailsId: null,
    }));
  }, []);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }, 500);
  }, [attendee.activeDay]);
  useEffect(() => {
    if (state.showMessage === true) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          showMessage: false,
        }));
      }, 3000);
    }
  }, [state.showMessage]);
  useEffect(() => {
    if (attendee.reset) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        setAttendee((prevState) => ({
          ...prevState,
          reset: false,
        }));
      }, 1000);
    }
  }, [attendee.reset]);
  useEffect(() => {
    attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  }, [attendee]);
  function updateAttendeeById(data) {
    return (
      attendeesContent &&
      attendeesContent.attendees.map((attendee) => {
        if (attendee._id === data._id) {
          // Return a new object with the updated properties
          return { ...attendee, ...data };
        }
        // Return the original object if the id does not match

        return attendee;
      })
    );
  }

  const onSubmitMessage = async (data) => {
    flagDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    delete data.checkin;
    delete data.checkinTime;
    const updatedData = {
      ...data,
      customMessage: "",
    };
    const oldData = attendeesContent;
    const updatedUpdates = {
      ...attendeesContent,
      attendees: updateAttendeeById(updatedData),
    };
    localStorage.setItem("attendeesContent", JSON.stringify(updatedUpdates));
    try {
      const res = await axios.post(`edit-attendee/${data._id}`, updatedData);
      setAttendee((prevState) => ({
        ...prevState,
        detailUpdate: true,
      }));
      flagDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setTimeout(() => {
        flagDispatch({
          type: "FETCH_SUCCESS",
        });
      }, 1000);
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      alert("Error: " + error.message);
      localStorage.setItem("attendeesContent", JSON.stringify(oldData));
      flagDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    flagDispatch({
      type: "FETCH_SUCCESS",
    });
  }, []);
  const MemoizedFilters = React.memo(Filters);
  const MemoizedSearchArea = React.memo(SearchArea);
  const countries =
    getAvailableCountries() && getAvailableCountries().length > 0
      ? true
      : false;
  const sectors =
    getAvailableSectors() && getAvailableSectors().length > 0 ? true : false;
  const attendees =
    attendeesContent && attendeesContent.attendees
      ? attendeesContent.attendees
      : [];
  return (
    <>
      <div className={`topAlertMessage ${state.showMessage ? "" : "hide"}`}>
        {state.actionMessage}
      </div>
      <div className={`topAlertMessage ${flagState.isSubmitted ? "" : "hide"}`}>
        Custom flag removed successfully
      </div>
      <Filters />
      <SearchArea />
      <CustomScrollbars style={{ height: `calc(100vh - 310px)` }}>
        <div
          className={`attendeesListHolder ${countries || sectors ? "adjustLength" : ""
            }`}
        >
          {attendee &&
            attendee.allAttendees &&
            attendee.allAttendees.length > 0 &&
            attendee.allAttendees
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((item, index) => {
                return (
                  <div key={index} className="attendeeListItemTop">
                    <div key={index} className="attendeeListItem">
                      <div className="checkBoxContainer">

                        <div
                          onDoubleClick={() => handleDoubleClick(item)}
                          className={`checkBox ${item.checkin === "Yes" && checkPermission("checkoutAttendee") === false ? "noPermission" : ""} ${item.checkin === "No" && checkPermission("checkinAttendee") === false ? "noPermission" : ""} ${item.registrationType === "Registered" ? "red" : ""
                            } ${item.checkin === "No" ? "" : "active"} `}
                        >
                          <i className="fa fa-check"></i>
                        </div>
                        <div
                          className="peopleGroup"
                          style={{
                            color: getColorOfPeopleGroup(
                              "background",
                              item.peopleGroup
                            ),
                          }}
                        >
                          {item.peopleGroup === "Owners"
                            ? getClientName()
                            : item.peopleGroup}
                        </div>
                      </div>
                      <div
                        className="details"
                        onClick={() => {
                          setLayout((prevState) => ({
                            ...prevState,
                            leftSideMenu: true,
                            activePage: "attendeeDetails",
                            activePageItems: headerItems("attendeeDetails"),
                          }));
                          setAttendee((prevState) => ({
                            ...prevState,
                            activeAttendeeDetailsId: item._id,
                          }));
                        }}
                      >
                        <AttendeeName item={item} />
                        {/* <p className="name attendeeName" >  {item.firstName}{" "}{item.lastName}{" "}</p> */}

                        {item.designation && (
                          <p className="designation">{item.designation}</p>
                        )}
                        {item.company && (
                          <p className="company">
                            {item.company}{" "}
                            {item.companyArabic && `(${item.companyArabic})`}
                          </p>
                        )}
                      </div>
                      <div className="action_container">
                        {checkPermission("editAttendee") &&
                          <div
                            onClick={() => {
                              setLayout((prevState) => ({
                                ...prevState,
                                leftSideMenu: true,
                                activePage: "editAttendee",
                                activePageItems: headerItems("editAttendee"),
                              }));
                              setAttendee((prevState) => ({
                                ...prevState,
                                activeAttendeeDetailsId: item._id,
                              }));
                            }}
                          >
                            <i
                              className="fa fa-pencil-square-o nomargin"
                              aria-hidden="true"
                            ></i>
                          </div>}
                        {checkPermission("printBadge") && getPrintBadgeValue() && (
                          <div
                            onClick={() => {
                              setLayout((prevState) => ({
                                ...prevState,

                                badgePrint: true,
                                badgePrintingAttendee: item,
                                badgeBack: "attendeesList",
                              }));
                              PrintBadgeAction(item._id);
                            }}
                          >
                            <i className="fa fa-print" aria-hidden="true"></i>
                          </div>
                        )}
                      </div>
                    </div>
                    {getAttendeeInfoMissing(item) && (
                      <div className="infoMissingRow">
                        <p className="infoMissing">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>{" "}
                          Information Required
                        </p>
                      </div>
                    )}
                    {item.customMessage && (
                      <div
                        className="infoMissingRow cursor-pointer"
                        title="Remove custom flag"
                        onClick={checkPermission("removeAttendeeCustomMessage") ? () => {
                          handleFlagShow(item);
                        } : () => { return false }}
                      >
                        {checkPermission("removeAttendeeCustomMessage") &&
                          <i
                            className="fa fa-times-circle me-1"
                            aria-hidden="true"
                          ></i>}
                        {item.customMessage}
                      </div>
                    )}
                    {item.tableInfo && (
                      <div className="infoMissingRow tableInfo">
                        <i
                          className="fa fa-sitemap me-1"
                          aria-hidden="true"
                        ></i>
                        Table: {item.tableInfo}
                      </div>
                    )}
                    {item.companions > 0 && (
                      <div className="infoMissingRow companion">
                        <p className="infoMissing">
                          <i className="fa fa-users" aria-hidden="true"></i>{" "}
                          {item.companions} Companion(s)
                        </p>
                      </div>
                    )}
                    {attendee.searchTerm != "" && item.unifiedId && (
                      <div className="infoMissingRow unifiedId">
                        <p className="infoMissing">
                          Unified ID :-{item.unifiedId}{" "}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

          {attendee &&
            attendee.allAttendees &&
            attendee.allAttendees.length <= 0 && (
              <h4 className="text-center py-5">No Records Found!</h4>
            )}

          <Modal
            show={state.show}
            centered
            className="undoCheckInModal"
            onHide={handleClose}
          >
            <Modal.Header>
              <Modal.Title>Confirm!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to undo check-in?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                CLOSE
              </Button>
              <Button
                variant="primaryColor"
                onClick={
                  state.registrationType === "Registered"
                    ? (e) => {
                      handleClose(e);
                      checkOutAttendee(state.checkOutValues);
                    }
                    : (e) => {
                      handleClose(e);
                      deleteAttendee(state.deleteValues);
                    }
                }
              >
                YES
              </Button>
            </Modal.Footer>
          </Modal>
          <ModalBody
            handleClose={handleFlagClose}
            handleShow={handleFlagShow}
            closeButton={false}
            heading="Confirm!"
            className="undoCheckInModal"
            show={show.flagShow}
            content={
              <RemoveCustomFlag
                handleClose={handleFlagClose}
                removeCustomFlag={() => onSubmitMessage(show.item)}
              />
            }
          />
        </div>
      </CustomScrollbars>
    </>
  );
};

export default AttendeesList;
