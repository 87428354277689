import AttendeesList from "../components/module/home/leftSidePartials/attendees/AttendeesList";
import AttendeeDetails from "../components/module/home/leftSidePartials/attendees/AttendeeDetails";
import Figures from "../components/module/home/leftSidePartials/figures/Figures";
import FiguresDetails from "../components/module/home/leftSidePartials/figures/details/FiguresDetails";
import CombinedReport from "../components/module/home/leftSidePartials/figures/combinedReport/CombinedReport";
import EditAttendee from "../components/module/home/leftSidePartials/attendees/EditAttendee";
import AddAttendee from "../components/module/home/leftSidePartials/attendees/AddAttendee";
import CombinedDetails from "../components/module/home/leftSidePartials/figures/combinedReport/CombinedDetails";
import { TotalAttendeesCombined } from "./combinedReportHelper";
import OverviewReport from "../components/module/home/leftSidePartials/overviewReport/OverviewReport";
import Notes from "../components/module/home/leftSidePartials/notes/Notes";
import FeaturesConfiguration from "../components/module/home/rightSidePartials/featuresConfiguration/FeaturesConfiguration";
import { checkFeature } from "./commonHelper";
import ManageCredentials from "../components/module/home/rightSidePartials/manageCredentials/ManageCredentials";
import Speakers from "../components/module/home/leftSidePartials/speakers/Speakers";
import SpeakerDetails from "../components/module/home/leftSidePartials/speakers/SpeakerDetails";
import AddSpeaker from "../components/module/home/leftSidePartials/speakers/AddSpeaker";
import EditSpeaker from "../components/module/home/leftSidePartials/speakers/EditSpeaker";
import Agenda from "../components/module/home/leftSidePartials/agenda/Agenda";
import Downloads from "../components/module/home/leftSidePartials/downloads/Downloads";
import UsefulDocuments from "../components/module/home/leftSidePartials/usefulDocuments/UsefulDocuments";
import MediaGallery from "../components/module/home/leftSidePartials/mediaGallery/MediaGallery";
import AddMedia from "../components/module/home/leftSidePartials/mediaGallery/AddMedia";
import OrganizerLog from "../components/module/home/leftSidePartials/organizerLog/OrganizerLog";
import DetailsTable from "../components/module/home/leftSidePartials/organizerLog/DetailsTable";
import DuplicateAttendee from "../components/module/home/leftSidePartials/duplicateAttendee/DuplicateAttendee";
import QuarantinedAttendee from "../components/module/home/leftSidePartials/quarantinedAttendee/QuarantinedAttendee";
import { checkPermission } from "./permissionsHelper";
import AboutAEP from "../components/module/home/leftSidePartials/aboutAEP/AboutAEP";

export const headerItems = (activePage) => {

    if (activePage === "attendeesList") {
        return ({ name: "Attendees List", icon: "fa fa-list", showExpand: false, component: <AttendeesList /> })
    }
    else if (activePage === "addAttendee") {
        return ({ name: "Add Attendee", icon: "icon icon-register", showExpand: false, component: <AddAttendee /> })
    }
    else if (activePage === "editAttendee") {
        return ({ name: "Edit Attendee", showExpand: false, component: <EditAttendee />, goBack: true, backPage: "attendeesList" })
    }
    else if (activePage === "attendeeDetails") {
        return ({ name: "Attendee's Details", icon: "fa fa-user", showExpand: false, component: <AttendeeDetails />, goBack: true, backPage: "attendeesList" })
    }
    else if (activePage === "figuresDetails") {
        return ({ name: "Figures Details", icon: "icon icon-figures", showExpand: false, component: <FiguresDetails />, figuresActiveGroup: "Partners", combinedReport: true, overviewReport: true, goBack: true, backPage: "figures" })
    }
    else if (activePage === "combinedDetails") {
        return ({ name: "Combined Report", showExpand: true, component: <CombinedDetails />, combinedReport: false, overviewReport: true, goBack: true, backPage: "combinedReport" })
    }
    else if (activePage === "figures") {
        return ({ name: "Figures", icon: "icon icon-figures", showExpand: true, component: <Figures />, combinedReport: true, overviewReport: true })
    }
    else if (activePage === "combinedReport") {
        return ({ name: "Combined Report", showExpand: true, component: <CombinedReport />, overviewReport: true, figures: true })
    }
    else if (activePage === "overviewReport") {
        return ({ name: "Overview Report", showExpand: false, component: <OverviewReport />, combinedReport: true, figures: true })
    }
    else if (activePage === "registerNew") {
        return ({ name: "Register New", showExpand: false, })
    }
    else if (activePage === "agenda") {
        return ({ name: "Agenda", icon: "icon icon-agenda", showExpand: false, component: <Agenda /> })
    }
    else if (activePage === "notes") {
        return ({ name: "Notes", icon: "icon icon-notes", showExpand: false, component: <Notes /> })
    }
    else if (activePage === "downloads") {
        return ({ name: "Downloads", icon: "icon icon-downloads", showExpand: false, component: <Downloads /> })
    }
    else if (activePage === "speakers") {
        return ({ name: "Speakers", icon: "icon icon-speakers", showExpand: false, component: <Speakers /> })
    }
    else if (activePage === "usefulDocuments") {
        return ({ name: "Useful Documents", icon: "icon icon-useful_documents", showExpand: false, component: <UsefulDocuments /> })
    }
    else if (activePage === "addSpeaker") {
        return ({ name: "Add Speaker", icon: "icon icon-speakers", showExpand: false, component: <AddSpeaker /> })
    }
    else if (activePage === "editSpeaker") {
        return ({ name: "Edit Speaker", icon: "icon icon-speakers", showExpand: false, component: <EditSpeaker />, goBack: true, backPage: "speakers" })
    }
    else if (activePage === "speakerDetails") {
        return ({ name: " Speaker's Details", icon: "icon icon-speakers", showExpand: false, component: <SpeakerDetails />, goBack: true, backPage: "speakers" })
    }
    else if (activePage === "mediaGallery") {
        return ({ name: "Media Gallery", icon: "icon icon-media_gallery", showExpand: false, component: <MediaGallery />, showExpand: false, })
    }
    else if (activePage === "addMediaGallery") {
        return ({ name: "Add Media", icon: "icon icon-media_gallery", component: <AddMedia />, showExpand: false, goBack: true, backPage: "mediaGallery" })
    }
    else if (activePage === "featuresConfiguration") {
        return ({ showExpand: false, component: <FeaturesConfiguration />, })
    }
    else if (activePage === "manageCredentials") {
        return ({ name: "Credentials", icon: "icon icon-credentials", showExpand: false, component: <ManageCredentials />, })
    }
    else if (activePage === "organizerLog") {
        return ({ name: " Organisers Activity Log", showExpand: false, component: <OrganizerLog />, })
    }
    else if (activePage === "duplicateAttendee") {
        return ({ name: "Duplicate Attendees", showExpand: false, component: <DuplicateAttendee />, })
    }
    else if (activePage === "quarantinedAttendee") {
        return ({ name: "Quarantined Attendees", showExpand: false, component: <QuarantinedAttendee />, })
    }
    else if (activePage === "organizerLogDetails") {
        return ({ name: " Organisers Activity Log", showExpand: false, component: <DetailsTable />, goBack: true, backPage: "organizerLog" })
    }
    else if (activePage === "aboutAEP") {
        return ({ name: "About AEP", icon: "icon icon-aep_icon", showExpand: false, component: <AboutAEP /> })
    }
    else {
        return null
    }

};
export const bottomSwiperItems = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    //console.log(eventData,"--------------------------->>>>>>>>>>>>>>swiper")
    const osmaFeatures = eventData && eventData.osmaFeatures
    if (!eventData) {
        // Return null or an appropriate fallback value
        return null;
    } else {
        const getIconOfFeature = (feature) => {

            if (feature === "agenda") {
                return { icon: "icon icon-agenda", subPages: [] }
            }
            if (feature === "speakers") {
                return { icon: "icon icon-speakers", subPages: ["editSpeaker", "addSpeaker", "speakerDetails"] }
            }
            if (feature === "mediaGallery") {
                return { icon: "icon icon-media_gallery", subPages: ["addMediaGallery"] }
            }
            if (feature === "questions") {
                return { icon: "icon icon-questions", subPages: [] }
            }
            if (feature === "notes") {
                return { icon: "icon icon-notes", subPages: [] }
            }
            if (feature === "figures") {
                return { icon: "icon icon-figures", subPages: [] }
            }
            if (feature === "downloads") {
                return { icon: "icon icon-downloads", subPages: [] }
            }
            if (feature === "tweets") {
                return { icon: "icon icon-tweets", subPages: [] }
            }
            if (feature === "feedbackForm") {
                return { icon: "icon icon-feedback_form", subPages: [] }
            }
            if (feature === "polls") {
                return { icon: "icon icon-polls", subPages: [] }
            }
            if (feature === "chat") {
                return { icon: "icon icon-chat", subPages: [] }
            }
            if (feature === "quizzes") {
                return { icon: "icon icon-quizzes", subPages: [] }
            }
            if (feature === "usefulDocuments") {
                return { icon: "icon icon-useful_documents", subPages: [] }
            }

        }
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        const items = osmaFeatures && osmaFeatures.filter((items) => checkFeature(items.feature) && items.status === "1").map(feature => {

            if (feature.isAllowedInBasic === "1") {
                return { ...feature, name: feature.feature, show: checkPermission(`showModule${capitalizeFirstLetter(feature.feature)}`), icon: getIconOfFeature(feature.feature).icon, subPages: getIconOfFeature(feature.feature).subPages, featureType: feature.featureType, pageName: feature.feature, mode: "Basic" };
            } else {
                return { ...feature, name: feature.feature, show: checkPermission(`showModule${capitalizeFirstLetter(feature.feature)}`), icon: getIconOfFeature(feature.feature).icon, subPages: getIconOfFeature(feature.feature).subPages, featureType: feature.featureType, pageName: feature.feature, mode: "Advanced" };
            }
        });



        return (items)
    }


};

export const bottomSwiperItemsAll = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    //console.log(eventData,"--------------------------->>>>>>>>>>>>>>swiper")
    const osmaFeatures = eventData && eventData.osmaFeatures
    if (!eventData) {
        // Return null or an appropriate fallback value
        return null;
    } else {
        const getIconOfFeature = (feature) => {

            if (feature === "agenda") {
                return { icon: "icon icon-agenda", subPages: [] }
            }
            if (feature === "speakers") {
                return { icon: "icon icon-speakers", subPages: ["editSpeaker", "addSpeaker", "speakerDetails"] }
            }
            if (feature === "mediaGallery") {
                return { icon: "icon icon-media_gallery", subPages: ["addMediaGallery"] }
            }
            if (feature === "questions") {
                return { icon: "icon icon-questions", subPages: [] }
            }
            if (feature === "notes") {
                return { icon: "icon icon-notes", subPages: [] }
            }
            if (feature === "figures") {
                return { icon: "icon icon-figures", subPages: [] }
            }
            if (feature === "downloads") {
                return { icon: "icon icon-downloads", subPages: [] }
            }
            if (feature === "tweets") {
                return { icon: "icon icon-tweets", subPages: [] }
            }
            if (feature === "feedbackForm") {
                return { icon: "icon icon-feedback_form", subPages: [] }
            }
            if (feature === "polls") {
                return { icon: "icon icon-polls", subPages: [] }
            }
            if (feature === "chat") {
                return { icon: "icon icon-chat", subPages: [] }
            }
            if (feature === "quizzes") {
                return { icon: "icon icon-quizzes", subPages: [] }
            }
            if (feature === "usefulDocuments") {
                return { icon: "icon icon-useful_documents", subPages: [] }
            }

        }
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        const items = osmaFeatures && osmaFeatures.filter((items) => checkFeature(items.feature)).map(feature => {

            if (feature.isAllowedInBasic === "1") {
                return { ...feature, name: feature.feature, show: checkPermission(`showModule${capitalizeFirstLetter(feature.feature)}`), icon: getIconOfFeature(feature.feature).icon, subPages: getIconOfFeature(feature.feature).subPages, featureType: feature.featureType, pageName: feature.feature, mode: "Basic" };
            } else {
                return { ...feature, name: feature.feature, show: checkPermission(`showModule${capitalizeFirstLetter(feature.feature)}`), icon: getIconOfFeature(feature.feature).icon, subPages: getIconOfFeature(feature.feature).subPages, featureType: feature.featureType, pageName: feature.feature, mode: "Advanced" };
            }
        });



        return (items)
    }


};
export const getClientName = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const client = eventData && eventData.client
    if (!eventData || !client) {
        // Return null or an appropriate fallback value
        return null;
    } else {
        if (client && client.name) {
            return (client.name)
        }

        return "Owner"
    }


};
export const personActions = () => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
        return attendeesContent.personActions
    }

};
export const peopleGroupData = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    return (
        {
            peopleGroupsRaw: eventData && eventData.peopleGroupsRaw,
            peopleGroupBackgroundColors: eventData && eventData.peopleGroupBackgroundColors,
            peopleGroupForegroundColors: eventData && eventData.peopleGroupForegroundColors
        })
}
export const removeAttendeeFromData = (values) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    const updatedData = attendees.filter(action => (action._id != values.attendeeId));
    return (updatedData)
}
export const removeObjectFromData = (values) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

    const updatedData = personActions.find(action => (action.personId === values.attendeeId && parseInt(action.dayId) === parseInt(values.dayId) && action.breakoutId === ((values.breakoutId === null || values.breakoutId === "null") ? null : values.breakoutId)));

    const difference = personActions.filter((item) => item._id !== updatedData._id);

    return (difference)
}
export const removedObjectFromData = (values) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

    const updatedData = personActions.find(action => (action.personId === values.attendeeId && parseInt(action.dayId) === parseInt(values.dayId) && action.breakoutId === ((values.breakoutId === null || values.breakoutId === "null") ? null : values.breakoutId)));
    const difference = personActions.filter((item) => item._id === updatedData._id);

    return (difference[0])
}
export const updatedAttendeesList = () => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {

        const activeDay = localStorage.getItem("activeDay");
        const activeBreakout = localStorage.getItem("activeBreakout");
        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
        const activeDayNumber = parseInt(activeDay);
        const activeBreakoutNumber = (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout;
        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === activeDayNumber) && (obj.breakoutId === activeBreakoutNumber)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });

        return newList;
    }

}
export const getAttendeeName = (id) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {

        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];

        const attendee = attendees.length > 0 && attendees.filter((obj) => (obj._id === id));
        if (attendee[0]) { return `${attendee[0].firstName} ${attendee[0].lastName}`; }


    }

}
export const updatedAttendeesListForFigures = () => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {

        const activeDay = localStorage.getItem("figuresActiveDay");
        const activeBreakout = localStorage.getItem("figuresActiveBreakout");
        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
        const activeDayNumber = parseInt(activeDay);
        const activeBreakoutNumber = (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout;
        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === activeDayNumber) && (obj.breakoutId === activeBreakoutNumber)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });

        return newList;
    }

}
export const updatedAttendeesListForOverView = (personActions) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {
        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const newList = [];
        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });

        return newList;
    }

}
export const getAttendeeDetails = (id) => {
    const list = updatedAttendeesList();

    if (id) {
        const details = list.find(action => action._id === id);
        return details
    }
    else {
        return undefined;
    }

}
export const getCheckinValue = (id) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    let activeDay = localStorage.getItem("activeDay");
    let activeBreakout = localStorage.getItem("activeBreakout");
    const activeBreakoutNumber = (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout;
    const data = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    const action = data.find(action => (action.personId === id && parseInt(action.dayId) === parseInt(activeDay) && action.breakoutId === activeBreakoutNumber));
    return action && action.checkin === "Yes" ? 1 : 0;
};
export const getCheckinOnADay = (id, dayId, breakoutId) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const activeBreakoutNumber = (breakoutId === "null" || breakoutId === null) ? null : breakoutId;
    const data = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];
    const action = data.find(action => (action.personId === id && (parseInt(action.dayId) === parseInt(dayId)) && (action.breakoutId === activeBreakoutNumber)));
    return action && action.checkin === "Yes" ? true : false;
};
export const getTitleArray = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const personTitles = eventData && eventData.personTitles ? eventData.personTitles : {}
    const transformedTitles = personTitles.map((item) => ({ value: item.value, label: item.value }));
    return (transformedTitles)
}

export const getIdFromPeopleGroup = (value) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const peopleGroups = eventData && eventData.peopleGroups ? eventData.peopleGroups : {}
    // Access the corresponding value from the peopleGroups object

    // Split the fullName by the hyphen to separate the group and name parts
    const parts = value.split('__');
    // Return the second part of the split, which is the name after the hyphen

    if (parts[1]) {
        return (parts[1]);
    }
    else { return "" }
}

export const getPeopleGroupFromGroup = (value) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const peopleGroups = eventData && eventData.peopleGroups ? eventData.peopleGroups : {}
    // Access the corresponding value from the peopleGroups object

    const fullName = peopleGroups[value];
    // Split the fullName by the hyphen to separate the group and name parts
    const parts = fullName.split(' - ');
    // Return the second part of the split, which is the name after the hyphen
    if (parts[0]) {
        return parts[0];
    }
    else { return "" }
}
export const getCompanyNameFromGroup = (value) => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const peopleGroups = eventData && eventData.peopleGroups ? eventData.peopleGroups : {}
    // Access the corresponding value from the peopleGroups object

    const fullName = value && peopleGroups[value];
    // Split the fullName by the hyphen to separate the group and name parts
    const parts = fullName ? fullName.split(' - ') : "";
    // Return the second part of the split, which is the name after the hyphen
    if (parts[1]) {
        return parts[1];
    }
    else { return "" }
}

export const getAllPeopleGroup = () => {
    const eventData = JSON.parse(localStorage.getItem("eventData"));
    const peopleGroups = eventData && eventData.peopleGroups ? eventData.peopleGroups : {}
    const transformedPeopleGroups = Object.entries(peopleGroups).map(([value, label]) => ({ label, value }));
    return (transformedPeopleGroups)
}

export const highestIdObject = () => {
    if (personActions().length > 0) {
        const ids = personActions().map(obj => obj.id);
        // Find the maximum id
        const maxId = Math.max(...ids);
        // Find the object with the maximum id

        return maxId
    }
    else {
        return 0
    }

}
export const highestIdAttendee = () => {
    if (updatedAttendeesList().length > 0) {
        const ids = updatedAttendeesList().map(obj => obj._id);
        // Find the maximum id
        const maxId = Math.max(...ids);
        // Find the object with the maximum id

        return maxId
    }
    else {
        return 0
    }

}
export const searchByName = (array, query, type) => {
    if (query && array) {
        const searchTerm = query.toLowerCase();

        // Filter the data array based on the search criteria
        if (type === "attendee") {
            const results = array.filter(obj => {
                const firstName = obj.firstName.toLowerCase();
                const lastName = obj.firstName.toLowerCase();
                const fullName = obj.fullName.toLowerCase();
                const company = obj.company.toLowerCase();
                const designation = obj.designation.toLowerCase();
                const unifiedId = obj.unifiedId ? obj.unifiedId : "";
                return firstName.includes(searchTerm) || lastName.includes(searchTerm) || fullName.includes(searchTerm) || company.includes(searchTerm) || designation.includes(searchTerm) || unifiedId.includes(searchTerm);
            });
            return results;
        }

        else {
            const results = array.filter(obj => {
                const firstName = obj.firstName.toLowerCase();
                const lastName = obj.firstName.toLowerCase();
                const fullName = obj.fullName.toLowerCase();
                const company = obj.company.toLowerCase();
                const designation = obj.designation.toLowerCase();
                const country = obj.country.toLowerCase();
                const nationality = obj.nationality.toLowerCase();
                const email = obj.email.toLowerCase();
                return firstName.includes(searchTerm) || lastName.includes(searchTerm) || country.includes(searchTerm) || nationality.includes(searchTerm) || email.includes(searchTerm) || fullName.includes(searchTerm) || company.includes(searchTerm) || designation.includes(searchTerm);
            });
            return results;
        }


    }
    else {
        return array
    }

}
export const showOnIndex = (array, query) => {
    if (query) {
        const searchTerm = query.toLowerCase();
        const results = array && array.filter((obj) => obj.initialLetter === searchTerm);
        return results;
    }
    else {
        return array
    }

}
export const formatCamelCase = (text) => {
    // Insert space before capital letters (except the first letter)
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const findPercentage = (number, total) => {
    if (total === 0) {
        return "0%";
    }
    var percentage = (number / total) * 100;
    return percentage.toFixed(1) + " " + "%";
}
export const getAvailableInitialLetters = () => {
    const availableLetters = [];
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    attendees.forEach((attendee) => {
        if (!availableLetters.includes(attendee.initialLetter)) {
            availableLetters.push(attendee.initialLetter);
        }
    });
    availableLetters.sort()
    return availableLetters;
};
export const getAvailableCountries = () => {
    const availableCountries = [];
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    attendees.forEach((attendee) => {
        if (attendee.country && !availableCountries.includes(attendee.country)) {
            availableCountries.push(attendee.country);
        }
    });
    return availableCountries;
};
export const getAvailableSectors = () => {
    const availableSectors = [];
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
    attendees.forEach((attendee) => {
        if (attendee.sector && !availableSectors.includes(attendee.sector)) {
            availableSectors.push(attendee.sector);
        }
    });
    return availableSectors;
};
export const getAvailablePeopleGroups = () => {
    const availablePeopleGroups = [];
    const peopleGroupsRaw = peopleGroupData().peopleGroupsRaw;
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];

    attendees.forEach((attendee) => {

        if (peopleGroupsRaw && peopleGroupsRaw[attendee.peopleGroup]) {

            if (!availablePeopleGroups.includes(attendee.peopleGroup)) {
                availablePeopleGroups.push(attendee.peopleGroup);
            }
        }
    });

    return availablePeopleGroups;
};
export const getAvailablePeopleGroupSpeakers = (array) => {
    const availablePeopleGroups = [];
    const peopleGroupsRaw = peopleGroupData().peopleGroupsRaw;

    array.forEach((attendee) => {

        if (peopleGroupsRaw && peopleGroupsRaw[attendee.peopleGroup]) {

            if (!availablePeopleGroups.includes(attendee.peopleGroup)) {
                availablePeopleGroups.push(attendee.peopleGroup);
            }
        }
    });

    return availablePeopleGroups;
};
export const TotalAttendees = (checkinStatus, peopleGroup, peopleType) => {
    const figuresSelectedEventDay = undefined;
    const figuresSelectedEventBreakout = undefined;
    const attendees = updatedAttendeesList();

    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);
    let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
    let totalCompanions = 0;

    if (totalActualAttendees > 0) {
        filteredAttendees.forEach(attendee => {
            if (attendee.companions) {
                totalCompanions += parseInt(attendee.companions);
            }
        });
    }

    return totalActualAttendees + totalCompanions;
}
export const getTotalAccounts = (checkinStatus, peopleGroup, peopleType) => {
    const attendees = updatedAttendeesList();
    const filteredAttendees = attendees && attendees.filter((attendee) => {
        if (checkinStatus !== undefined && attendee.checkin !== checkinStatus) {
            return false;
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined' && attendee.peopleGroup !== peopleGroup) {
            return false;
        }

        if (peopleType !== undefined && attendee.registrationType !== peopleType) {
            return false;
        }

        return true;
    });

    const accounts = [];

    filteredAttendees && filteredAttendees.forEach((attendee) => {
        if (attendee.company) {
            let isExisting = false;

            accounts.forEach((companyName) => {
                if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                    isExisting = true;
                    return;
                }
            });

            if (!isExisting) {
                accounts.push(attendee.company);
            }
        }
    });

    return accounts.length;
};
export const getTotalCountries = (checkinStatus, peopleGroup, peopleType) => {
    const attendees = updatedAttendeesList();
    const filteredAttendees = attendees && attendees.filter((attendee) => {
        if (checkinStatus !== undefined && attendee.checkin !== checkinStatus) {
            return false;
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined' && attendee.peopleGroup !== peopleGroup) {
            return false;
        }

        if (peopleType !== undefined && attendee.registrationType !== peopleType) {
            return false;
        }

        return true;
    });

    const accounts = [];

    filteredAttendees && filteredAttendees.forEach((attendee) => {
        if (attendee.country) {
            let isExisting = false;

            accounts.forEach((countryName) => {
                if (countryName.toLowerCase() === attendee.country.toLowerCase()) {
                    isExisting = true;
                    return;
                }
            });

            if (!isExisting) {
                accounts.push(attendee.country);
            }
        }
    });

    return accounts.length;
};
export const TotalAttendeesFigures = (checkinStatus, peopleGroup, peopleType) => {
    const figuresSelectedEventDay = undefined;
    const figuresSelectedEventBreakout = undefined;
    const attendees = updatedAttendeesListForFigures();

    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);
    let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
    let totalCompanions = 0;

    if (totalActualAttendees > 0) {
        filteredAttendees.forEach(attendee => {
            if (attendee.companions) {
                totalCompanions += parseInt(attendee.companions);
            }
        });
    }

    return totalActualAttendees + totalCompanions;
}
export const TotalAttendeesFiguresOverView = (checkinStatus, peopleGroup, peopleType, overview) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const activeDay = localStorage.getItem("figuresActiveDay");
    const activeBreakout = localStorage.getItem("figuresActiveBreakout");
    const activeDayNumber = parseInt(activeDay);
    const activeBreakoutNumber = (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout;
    const personActions = attendeesContent.personActions
    const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === activeDayNumber))
    function removeDuplicatePersonIds() {
        return action.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const attendees = updatedAttendeesListForOverView(filteredActions);
    const filter = (array, condition) => array && array.filter(item => {
        for (const key in condition) {
            if (item[key] !== condition[key]) {
                return false;
            }
        }
        return true;
    });

    let condition = {};

    if (checkinStatus !== undefined) {
        condition.checkin = checkinStatus
    }

    if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
        condition.peopleGroup = peopleGroup;
    }

    if (peopleType !== undefined) {
        condition.registrationType = peopleType;
    }

    const filteredAttendees = filter(attendees, condition);

    let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
    let totalCompanions = 0;

    if (totalActualAttendees > 0) {
        filteredAttendees.forEach(attendee => {
            if (attendee.companions) {
                totalCompanions += parseInt(attendee.companions);
            }
        });
    }

    return totalActualAttendees + totalCompanions;
}

export const getTotalAccountsOverview = (checkinStatus, peopleGroup, peopleType) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
    const activeDay = localStorage.getItem("figuresActiveDay");
    const activeBreakout = localStorage.getItem("figuresActiveBreakout");
    const activeDayNumber = parseInt(activeDay);
    const activeBreakoutNumber = (activeBreakout === "null" || activeBreakout === null) ? null : activeBreakout;
    const personActions = attendeesContent.personActions
    const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === activeDayNumber))
    function removeDuplicatePersonIds() {
        return action.reduce((uniqueIds, obj) => {
            // Check if the current object's personId is not already in the uniqueIds array
            if (!uniqueIds.some(id => id.personId === obj.personId)) {
                // Add the current object's personId to the uniqueIds array
                uniqueIds.push(obj);
            }
            // Return the updated uniqueIds array for the next iteration
            return uniqueIds;
        }, []);
    }
    const filteredActions = removeDuplicatePersonIds();
    const attendees = updatedAttendeesListForOverView(filteredActions);
    const filteredAttendees = attendees && attendees.filter((attendee) => {
        if (checkinStatus !== undefined && attendee.checkin !== checkinStatus) {
            return false;
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined' && attendee.peopleGroup !== peopleGroup) {
            return false;
        }

        if (peopleType !== undefined && attendee.registrationType !== peopleType) {
            return false;
        }

        return true;
    });

    const accounts = [];

    filteredAttendees && filteredAttendees.forEach((attendee) => {
        if (attendee.company) {
            let isExisting = false;

            accounts.forEach((companyName) => {
                if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                    isExisting = true;
                    return;
                }
            });

            if (!isExisting) {
                accounts.push(attendee.company);
            }
        }
    });

    return accounts.length;
};
export const getTotalAccountsFigures = (checkinStatus, peopleGroup, peopleType) => {
    const attendees = updatedAttendeesListForFigures();
    const filteredAttendees = attendees && attendees.filter((attendee) => {
        if (checkinStatus !== undefined && attendee.checkin !== checkinStatus) {
            return false;
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined' && attendee.peopleGroup !== peopleGroup) {
            return false;
        }

        if (peopleType !== undefined && attendee.registrationType !== peopleType) {
            return false;
        }

        return true;
    });

    const accounts = [];

    filteredAttendees && filteredAttendees.forEach((attendee) => {
        if (attendee.company) {
            let isExisting = false;

            accounts.forEach((companyName) => {
                if (companyName.toLowerCase() === attendee.company.toLowerCase()) {
                    isExisting = true;
                    return;
                }
            });

            if (!isExisting) {
                accounts.push(attendee.company);
            }
        }
    });

    return accounts.length;
};
export const getColorOfPeopleGroup = (type, peopleGroup) => {
    const foreground = peopleGroupData().peopleGroupForegroundColors;
    const background = peopleGroupData().peopleGroupBackgroundColors;
    if (type === undefined) {
        return false;
    }
    if (type === 'background') {

        if (peopleGroup != 'total') {
            return background[peopleGroup]
        }
        else {
            return "transparent"
        }

    }
    if (type === 'foreground') {

        if (peopleGroup != 'total') {
            return foreground[peopleGroup]
        }
        else {
            return "white"
        }

    }
};

export const BuildGraphData = (type, peopleGroupSelection, graphPartSelection, figures, setFigures) => {
    if (type === 'attendees') {
        const totalCheckedIns = TotalAttendeesFigures('Yes', peopleGroupSelection, undefined);
        const totalRegisteredCheckedIns = TotalAttendeesFigures('Yes', peopleGroupSelection, 'Registered');
        const totalWalkinCheckedIns = TotalAttendeesFigures('Yes', peopleGroupSelection, 'Walkin');

        if (totalCheckedIns === 0 || (totalRegisteredCheckedIns === 0 && totalWalkinCheckedIns === 0)) {

            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    title: {
                        text: '<div className="graph_title">Total ' + (peopleGroupSelection ? peopleGroupSelection : 'Attendees') + '</div>',
                        useHTML: true,
                        style: {
                            color: '#fff',
                            marginBottom: 20,
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        attendeeListFilters: { ...figures.attendeeListFilters, registrationType: event.point.rawname === "Registered" ? "Registered" : "Walkin" }
                                    }));
                                }
                            },
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Registered') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Registered</span>' +
                            '<span style="' + ((graphPartSelection === 'Walkin') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Walk-Ins</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'Walk-In (' + totalWalkinCheckedIns + ')',
                                y: totalWalkinCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Walkin',
                                selected: graphPartSelection === 'Walkin',
                                sliced: graphPartSelection === 'Walkin',
                                color: graphPartSelection === 'Walkin' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            },
                            {
                                name: 'Registered (' + totalRegisteredCheckedIns + ')',
                                y: totalRegisteredCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Registered',
                                selected: graphPartSelection === 'Registered',
                                sliced: graphPartSelection === 'Registered',
                                color: graphPartSelection === 'Registered' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'company') {
        const totalAccounts = getTotalAccounts(undefined, peopleGroupSelection, undefined);
        const totalAccountsShows = getTotalAccounts('Yes', peopleGroupSelection, undefined);
        const totalAccountsNoShows = totalAccounts - totalAccountsShows;
        if (totalAccounts === 0 || (totalAccountsShows === 0 && totalAccountsNoShows === 0)) {
            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        companyListFilters: { checkin: event.point.rawname }
                                    }));
                                }
                            },
                        }
                    },
                    title: {
                        text: '<div className="graph_title">' + (peopleGroupSelection || 'Total') + ' Accounts</div>',
                        useHTML: true,
                        style: {
                            color: '#fff'
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Shows</span>' +
                            '<span style="' + ((graphPartSelection === 'No-Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'No-Shows</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'No-Shows  (' + totalAccountsNoShows + ')',
                                y: totalAccountsNoShows * 100 / totalAccounts,
                                rawname: 'No-Shows-Accounts',
                                selected: graphPartSelection === 'No-Shows-Accounts',
                                sliced: graphPartSelection === 'No-Shows-Accounts',
                                color: graphPartSelection === 'No-Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            },
                            {
                                name: 'Shows (' + totalAccountsShows + ')',
                                y: totalAccountsShows * 100 / totalAccounts,
                                rawname: 'Shows-Accounts',
                                selected: graphPartSelection === 'Shows-Accounts',
                                sliced: graphPartSelection === 'Shows-Accounts',
                                color: graphPartSelection === 'Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'country') {
        const totalAccounts = getTotalCountries(undefined, undefined, undefined);
        const totalAccountsShows = getTotalCountries('Yes', undefined, undefined);
        const totalAccountsNoShows = totalAccounts - totalAccountsShows;
        if (totalAccounts === 0 || (totalAccountsShows === 0 && totalAccountsNoShows === 0)) {
            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        countryListFilters: { checkin: event.point.rawname }
                                    }));
                                }
                            },
                        }
                    },
                    title: {
                        text: '<div className="graph_title">' + 'Total' + ' Countries</div>',
                        useHTML: true,
                        style: {
                            color: '#fff'
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Shows</span>' +
                            '<span style="' + ((graphPartSelection === 'No-Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'No-Shows</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'No-Shows  (' + totalAccountsNoShows + ')',
                                y: totalAccountsNoShows * 100 / totalAccounts,
                                rawname: 'No-Shows-Accounts',
                                selected: graphPartSelection === 'No-Shows-Accounts',
                                sliced: graphPartSelection === 'No-Shows-Accounts',
                                color: graphPartSelection === 'No-Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            },
                            {
                                name: 'Shows (' + totalAccountsShows + ')',
                                y: totalAccountsShows * 100 / totalAccounts,
                                rawname: 'Shows-Accounts',
                                selected: graphPartSelection === 'Shows-Accounts',
                                sliced: graphPartSelection === 'Shows-Accounts',
                                color: graphPartSelection === 'Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'registered') {
        const totalAccounts = TotalAttendeesFigures(undefined, peopleGroupSelection, "Registered");
        const totalAccountsShows = TotalAttendeesFigures('Yes', peopleGroupSelection, "Registered");
        const totalAccountsNoShows = totalAccounts - totalAccountsShows;

        if (totalAccounts === 0 || (totalAccountsShows === 0 && totalAccountsNoShows === 0)) {
            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setFigures((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        companyListFilters: { checkin: event.point.rawname }
                                    }));
                                }
                            },
                        }
                    },
                    title: {
                        text: '<div className="graph_title">' + (peopleGroupSelection || 'Total') + ' Registered</div>',
                        useHTML: true,
                        style: {
                            color: '#fff'
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Shows</span>' +
                            '<span style="' + ((graphPartSelection === 'No-Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'No-Shows</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'No-Shows (' + totalAccountsNoShows + ')',
                                y: totalAccountsNoShows * 100 / totalAccounts,
                                rawname: 'No-Shows-Accounts',
                                selected: graphPartSelection === 'No-Shows-Accounts',
                                sliced: graphPartSelection === 'No-Shows-Accounts',
                                color: graphPartSelection === 'No-Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            },
                            {
                                name: 'Shows  (' + totalAccountsShows + ')',
                                y: totalAccountsShows * 100 / totalAccounts,
                                rawname: 'Shows-Accounts',
                                selected: graphPartSelection === 'Shows-Accounts',
                                sliced: graphPartSelection === 'Shows-Accounts',
                                color: graphPartSelection === 'Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else {
        return false
    }
}

export const BuildGraphDataCombined = (type, graphPartSelection, combined, setCombined) => {
    if (type === 'attendees') {
        const totalCheckedIns = TotalAttendeesCombined('Yes', undefined, undefined);
        const totalRegisteredCheckedIns = TotalAttendeesCombined('Yes', undefined, 'Registered');
        const totalWalkinCheckedIns = TotalAttendeesCombined('Yes', undefined, 'Walkin');

        if (totalCheckedIns === 0 || (totalRegisteredCheckedIns === 0 && totalWalkinCheckedIns === 0)) {

            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    title: {
                        text: '<div className="graph_title">Total ' + 'Attendees' + '</div>',
                        useHTML: true,
                        style: {
                            color: '#fff',
                            marginBottom: 20,
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        attendeeListFilters: { ...combined.attendeeListFilters, registrationType: event.point.rawname === "Registered" ? "Registered" : "Walkin" }
                                    }));
                                }
                            },
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Registered') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Registered</span>' +
                            '<span style="' + ((graphPartSelection === 'Walkin') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Walk-Ins</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'Walk-In (' + totalWalkinCheckedIns + ')',
                                y: totalWalkinCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Walkin',
                                selected: graphPartSelection === 'Walkin',
                                sliced: graphPartSelection === 'Walkin',
                                color: graphPartSelection === 'Walkin' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            },
                            {
                                name: 'Registered (' + totalRegisteredCheckedIns + ')',
                                y: totalRegisteredCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Registered',
                                selected: graphPartSelection === 'Registered',
                                sliced: graphPartSelection === 'Registered',
                                color: graphPartSelection === 'Registered' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'company') {
        const totalAccounts = getTotalAccounts(undefined, undefined, undefined);
        const totalAccountsShows = getTotalAccounts('Yes', undefined, undefined);
        const totalAccountsNoShows = totalAccounts - totalAccountsShows;
        if (totalAccounts === 0 || (totalAccountsShows === 0 && totalAccountsNoShows === 0)) {
            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        companyListFilters: { checkin: event.point.rawname }
                                    }));
                                }
                            },
                        }
                    },
                    title: {
                        text: '<div className="graph_title">' + 'Total' + ' Accounts</div>',
                        useHTML: true,
                        style: {
                            color: '#fff'
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Shows</span>' +
                            '<span style="' + ((graphPartSelection === 'No-Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'No-Shows</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'No-Shows  (' + totalAccountsNoShows + ')',
                                y: totalAccountsNoShows * 100 / totalAccounts,
                                rawname: 'No-Shows-Accounts',
                                selected: graphPartSelection === 'No-Shows-Accounts',
                                sliced: graphPartSelection === 'No-Shows-Accounts',
                                color: graphPartSelection === 'No-Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            },
                            {
                                name: 'Shows (' + totalAccountsShows + ')',
                                y: totalAccountsShows * 100 / totalAccounts,
                                rawname: 'Shows-Accounts',
                                selected: graphPartSelection === 'Shows-Accounts',
                                sliced: graphPartSelection === 'Shows-Accounts',
                                color: graphPartSelection === 'Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'country') {
        const totalAccounts = getTotalCountries(undefined, undefined, undefined);
        const totalAccountsShows = getTotalCountries('Yes', undefined, undefined);
        const totalAccountsNoShows = totalAccounts - totalAccountsShows;
        if (totalAccounts === 0 || (totalAccountsShows === 0 && totalAccountsNoShows === 0)) {
            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        countryListFilters: { checkin: event.point.rawname }
                                    }));
                                }
                            },
                        }
                    },
                    title: {
                        text: '<div className="graph_title">' + 'Total' + ' Countries</div>',
                        useHTML: true,
                        style: {
                            color: '#fff'
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Shows</span>' +
                            '<span style="' + ((graphPartSelection === 'No-Shows-Accounts') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'No-Shows</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'No-Shows  (' + totalAccountsNoShows + ')',
                                y: totalAccountsNoShows * 100 / totalAccounts,
                                rawname: 'No-Shows-Accounts',
                                selected: graphPartSelection === 'No-Shows-Accounts',
                                sliced: graphPartSelection === 'No-Shows-Accounts',
                                color: graphPartSelection === 'No-Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            },
                            {
                                name: 'Shows (' + totalAccountsShows + ')',
                                y: totalAccountsShows * 100 / totalAccounts,
                                rawname: 'Shows-Accounts',
                                selected: graphPartSelection === 'Shows-Accounts',
                                sliced: graphPartSelection === 'Shows-Accounts',
                                color: graphPartSelection === 'Shows-Accounts' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else if (type === 'dayBreakout') {
        const totalCheckedIns = TotalAttendeesByDayBreakout('Yes', undefined, undefined, combined.day, combined.breakout);
        const totalRegisteredCheckedIns = TotalAttendeesByDayBreakout('Yes', undefined, 'Registered', combined.day, combined.breakout);
        const totalWalkinCheckedIns = TotalAttendeesByDayBreakout('Yes', undefined, 'Walkin', combined.day, combined.breakout);

        if (totalCheckedIns === 0 || (totalRegisteredCheckedIns === 0 && totalWalkinCheckedIns === 0)) {

            return false;
        } else {
            return ({
                chartConfig: {
                    chart: {
                        type: 'pie',
                        backgroundColor: "#303030",
                    },
                    tooltip: {

                        pointFormat: '<b>{point.percentage:.1f}%</b>'

                    },
                    title: {
                        text: '<div className="graph_title">Total ' + 'Attendees' + '</div>',
                        useHTML: true,
                        style: {
                            color: '#fff',
                            marginBottom: 20,
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            },
                            events: {
                                click: function (event) {
                                    setCombined((prevState) => ({
                                        ...prevState,
                                        graphPartSelection: event.point.rawname,
                                        attendeeListFilters: { ...combined.attendeeListFilters, registrationType: event.point.rawname === "Registered" ? "Registered" : "Walkin" }
                                    }));
                                }
                            },
                        }
                    },
                    subtitle: {
                        text: '<div style="font-size:14px;">' +
                            '<span style="margin-right:10px;' + ((graphPartSelection === 'Registered') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Registered</span>' +
                            '<span style="' + ((graphPartSelection === 'Walkin') ? 'background-color:#c53d95;color:#ffffff;padding:6px 12px;' : '') + '">' +
                            'Walk-Ins</span>' +
                            '</div>',
                        useHTML: true

                    },
                    series: [{
                        data: [
                            {
                                name: 'Walk-In (' + totalWalkinCheckedIns + ')',
                                y: totalWalkinCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Walkin',
                                selected: graphPartSelection === 'Walkin',
                                sliced: graphPartSelection === 'Walkin',
                                color: graphPartSelection === 'Walkin' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#72BF44' : 'white')
                            },
                            {
                                name: 'Registered (' + totalRegisteredCheckedIns + ')',
                                y: totalRegisteredCheckedIns * 100 / totalCheckedIns,
                                rawname: 'Registered',
                                selected: graphPartSelection === 'Registered',
                                sliced: graphPartSelection === 'Registered',
                                color: graphPartSelection === 'Registered' ? '#CC23A7' : (graphPartSelection === undefined || graphPartSelection === 'undefined' ? '#CECECE' : 'white')
                            }
                        ]
                    }]
                }
            })
        }
    }
    else {
        return false
    }
}

export const TotalAttendeesByDayBreakout = (checkinStatus, peopleGroup, peopleType, day, breakout) => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else if (day === "" && breakout === "") {
        const value = TotalAttendeesCombined();
        return value;
    }
    else {

        const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        const personActions = attendeesContent && attendeesContent.personActions ? attendeesContent.personActions : [];

        const newList = [];

        attendees.sort((a, b) => a.fullName?.localeCompare(b.fullName)).map(attendee => {
            const action = personActions.length > 0 && personActions.filter((obj) => (parseInt(obj.dayId) === parseInt(day)) && (obj.breakoutId === breakout)).find(action => action.personId === attendee._id);
            if (action) {
                newList.push({ ...attendee, checkin: action.checkin, checkinTime: action.checkinTime });
            }
            else {
                newList.push({ ...attendee, checkin: "No" })
            }
        });
        const filter = (array, condition) => array && array.filter(item => {
            for (const key in condition) {
                if (item[key] !== condition[key]) {
                    return false;
                }
            }
            return true;
        });

        let condition = {};

        if (checkinStatus !== undefined) {
            condition.checkin = checkinStatus
        }

        if (peopleGroup !== undefined && peopleGroup !== 'undefined') {
            condition.peopleGroup = peopleGroup;
        }

        if (peopleType !== undefined) {
            condition.registrationType = peopleType;
        }

        const filteredAttendees = filter(newList, condition);
        let totalActualAttendees = filteredAttendees && filteredAttendees.length || 0;
        let totalCompanions = 0;

        if (totalActualAttendees > 0) {
            filteredAttendees.forEach(attendee => {
                if (attendee.companions) {
                    totalCompanions += parseInt(attendee.companions);
                }
            });
        }

        return totalActualAttendees + totalCompanions;

    }

}
export const getStatusByDay = (dayValue) => {
    const content = JSON.parse(localStorage.getItem("eventData"));
    const eventDays = content && content.eventDays
    const dayEntry = eventDays.find(day => (day.day === dayValue));

    if (dayEntry.status === "1") {
        return true;
    }
    else {
        return false
    }

};

export const duplicateAttendees = () => {
    const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));

    if (!attendeesContent) {
        // Return null or an appropriate fallback value
        return null;
    }
    else {
        const duplicates = [];
        const items = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
        for (let i = 0; i < items.length; i++) {
            for (let j = i + 1; j < items.length; j++) {
                if (items[i].fullName === items[j].fullName) {
                    duplicates.push({
                        item1: items[i],
                        item2: items[j],
                        type: "name"
                    });
                }
                else if (items[i].email != "" && items[j].email != "" && (items[i].email === items[j].email)) {
                    duplicates.push({
                        item1: items[i],
                        item2: items[j],
                        type: "email"
                    });
                }
            }
        }
        return duplicates;
    }

};