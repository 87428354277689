import { Formik } from "formik";
import React, { useContext, useEffect, useState, useReducer } from "react";
import { Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import CMSFormSelect from "../../../../../UI/shared/formFields/CMSFormSelect";
import { GiExpand } from "react-icons/gi";
import {
    arraySortByProperty,
    getCompanies,
    getConditionForFigures,
    getCountOfAttended,
    getCountOfAttendedCountry,
    getCountOfRegistered,
    getCountOfRegisteredCountry,
    getCountOfTotal,
    getCountOfTotalCountries,
    getCountries,
    getFiguresList,
    getFilteredAttendeesForFigures,
    getFilteredCompaniesForFigures,
    getGroupByCompany,
    getListBasedOnCompany,
    getListBasedOnCountry,
} from "../../../../../../helper/figuresListHelper";
import "../figures.scss";
import { FiguresContext } from "../../../../../../context/FiguresContext";
import {
    TotalAttendees,
    getAvailablePeopleGroups,
    getCheckinValue,
    getColorOfPeopleGroup,
    getTotalAccounts,
    getTotalCountries,
} from "../../../../../../helper/sideHeaderHelper";
import { handleFormatArray, titleOfBreakout, titleOfDay } from "../../../../../../helper/commonHelper";
import Loading from "../../../../../UI/shared/loading/Loading";
import { HomeScreenContext } from "../../../../../../context/HomeScreenContext";
import PieChart from "./PieChart";
import CustomScrollbars from "../../../../../UI/shared/customScrollbar/CustomScrollbar";
import { updateInitialState, updateReducer } from "../../../../../../reducers/updateReducer";
import axios from "../../../../../../config/axios";
import { AuthContext } from "../../../../../../context/AuthContext";
import Download from "../../../partials/downloadButton/Download";
import { getCheckinValueByDayBreakout } from "../../../../../../helper/combinedReportHelper";
import useApiUpload from "../../../../../UI/shared/addToDownload/AddToDownload";
import { checkPermission } from "../../../../../../helper/permissionsHelper";

const ListSection = () => {
    const { figures, setFigures } = useContext(FiguresContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const condition = getConditionForFigures(figures, setFigures);
    const [activeCompany, setActiveCompany] = useState("");
    const [activeCountry, setActiveCountry] = useState("");
    const attendees = getFiguresList(
        condition.checkin,
        condition.peopleGroup,
        condition.type
    );
    let activeDay = localStorage.getItem("figuresActiveDay");
    let activeBreakout = localStorage.getItem("figuresActiveBreakout");
    const availablePeopleGroups = getAvailablePeopleGroups();
    const [filteredAttendees, setFilteredAttendees] = useState(attendees);
    const [filteredCompanies, setFilteredCompanies] = useState();
    const [filteredCountries, setFilteredCountries] = useState();
    const { userAuthState, setUser } = useContext(AuthContext);
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    function generateArrayOfIds(array) {
        return array.map(obj => obj._id);
    }
    const { SaveToDownloads } = useApiUpload();
    const handleDownload = async () => {
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        const attendees = arraySortByProperty(filteredAttendees, figures.listSort);

        const ids = generateArrayOfIds(attendees);

        try {
            const res = await axios.post(`download/${userAuthState.user && userAuthState.user.activityId}`, {
                ids: ids,
                dayId: activeDay,
                breakoutId: activeBreakout,
                type: "figures"
            });
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });

            const name = figures.activeBreakout != null ? `${titleOfBreakout(activeBreakout)} - ${titleOfDay(activeDay)}` : titleOfDay(activeDay);
            const peopleGroupSelected = (figures.peopleGroupSelection && figures.peopleGroupSelection !== "" && figures.peopleGroupSelection !== "undefined") ? figures.peopleGroupSelection : "All";



            const utf8Bom = '\uFEFF'; // UTF-8 BOM
            const csvContent = utf8Bom + res.data;

            const blob = new Blob([csvContent], { type: "text/csv; charset=utf-8" });
            const downloadUrl = window.URL.createObjectURL(blob);
            const filename = `Report(${peopleGroupSelected})-${name}.csv`;
            const a = document.createElement("a");
            const file = new File([blob], filename, { type: "text/csv;charset=utf-8", lastModified: Date.now() });
            try {
                const result = await SaveToDownloads(file, filename);

            } catch (error) {
                console.error(error); // Handle failure
            }
            if (typeof a.download === "undefined") {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

            }
        } catch (error) {
            console.error("There was a problem with the download operation:", error);
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });

            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };

    useEffect(() => {
        updateDispatch({
            type: "FETCH_SUCCESS",
        });

    }, [])
    useEffect(() => {
        const filteredAttendees2 = getFilteredAttendeesForFigures(
            attendees,
            figures.attendeeListFilters
        );
        setFilteredAttendees(filteredAttendees2);
        setFilteredCompanies(getCompanies(attendees, figures.peopleGroupSelection));
        setFilteredCountries(getCountries(attendees, figures.peopleGroupSelection));
    }, [figures]);
    useEffect(() => {
        const filteredAttendees2 = getFilteredAttendeesForFigures(
            attendees,
            figures.attendeeListFilters
        );
        setFilteredAttendees(filteredAttendees2);
        setFilteredCompanies(getCompanies(attendees, figures.peopleGroupSelection));
        setFilteredCountries(getCountries(attendees, figures.peopleGroupSelection));
    }, []);
    const [state, setState] = useState({
        loading: false,
        chartOnMobile: false,
    });
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
        }, 500);
    }, []);
    const totalAccounts = getTotalAccounts(
        undefined,
        figures.peopleGroupSelection,
        undefined
    );
    const totalAccountsShows = getTotalAccounts(
        "Yes",
        figures.peopleGroupSelection,
        undefined
    );
    const totalAccountsNoShows = totalAccounts - totalAccountsShows;
    const totalCountries = getTotalCountries(
        undefined,
        figures.peopleGroupSelection,
        undefined
    );
    const totalCountriesShows = getTotalCountries(
        "Yes",
        figures.peopleGroupSelection,
        undefined
    );
    const totalCountriesNoShows = totalAccounts - totalAccountsShows;
    return (
        <div
            className={`rightSectionHolder ${layout.showFigureListViewFullScreen ? "fixed" : ""
                }`}
        >
            {state.loading && <Loading />}
            {!state.loading && (
                <Row className="g-0">
                    {state.chartOnMobile ? (
                        <Col className="chartOnMobile">
                            <PieChart />
                        </Col>
                    ) : (
                        <Col>

                            <div className="darkGrayHeader">
                                {(activeCompany === "" && activeCountry === "") ?
                                    <>
                                        <Formik
                                            initialValues={{
                                                peopleGroup:
                                                    figures.peopleGroupSelection &&
                                                        figures.peopleGroupSelection
                                                        ? figures.peopleGroupSelection
                                                        : "",
                                                registrationType:
                                                    figures.graphPartSelection &&
                                                        figures.graphPartSelection !== undefined
                                                        ? figures.graphPartSelection
                                                        : "",
                                                checkin: figures.typeSelection === "country" ? figures.countryListFilters &&
                                                    figures.countryListFilters.checkin
                                                    ? figures.countryListFilters.checkin
                                                    : "" : figures.typeSelection === "company" ?
                                                    figures.companyListFilters &&
                                                        figures.companyListFilters.checkin
                                                        ? figures.companyListFilters.checkin
                                                        : "" : "",
                                            }}
                                            enableReinitialize={true}
                                            validateOnChange={true}
                                            validateOnBlur={true}
                                            onSubmit={(values) => {
                                                delete values.checkin;
                                                if (figures.typeSelection === "registered") {
                                                    setFigures((prevState) => ({
                                                        ...prevState,
                                                        attendeeListFilters: {},
                                                    }));
                                                }
                                                else {
                                                    setFigures((prevState) => ({
                                                        ...prevState,
                                                        attendeeListFilters: values,
                                                    }));
                                                }

                                            }}
                                        >
                                            {(form) => (
                                                <Form className="widthInherit" onSubmit={form.handleSubmit}>
                                                    <Row className="listSectionFilter">
                                                        {(figures.typeSelection === "attendees" || figures.typeSelection === "registered") && (
                                                            <>
                                                                <Col className="pe-0 me-0">
                                                                    <CMSFormSelect
                                                                        name="peopleGroup"
                                                                        placeholder={{ label: "All", value: "" }}
                                                                        form={form}
                                                                        onChange={(e) => {
                                                                            form.handleChange(e);
                                                                            form.handleSubmit();
                                                                            setFigures((prevState) => ({
                                                                                ...prevState,
                                                                                peopleGroupSelection: e.target.value === "" ? undefined : e.target.value,
                                                                                typeSelection: figures.typeSelection,
                                                                            }));
                                                                        }}
                                                                        options={handleFormatArray(
                                                                            availablePeopleGroups
                                                                        )}
                                                                    />
                                                                </Col>
                                                                {(figures.typeSelection === "attendees") && (
                                                                    <Col>

                                                                        <CMSFormSelect
                                                                            name="registrationType"
                                                                            onChange={(e) => {
                                                                                form.handleChange(e);
                                                                                form.handleSubmit();
                                                                                setFigures((prevState) => ({
                                                                                    ...prevState,
                                                                                    peopleGroupSelection:
                                                                                        figures.peopleGroupSelection,
                                                                                    graphPartSelection: e.target.value === "" ? undefined : e.target.value,
                                                                                    typeSelection: "attendees",
                                                                                }));
                                                                            }}
                                                                            placeholder={{ label: "All", value: "" }}
                                                                            form={form}
                                                                            options={[
                                                                                {
                                                                                    label: "Registered",
                                                                                    value: "Registered",
                                                                                },
                                                                                { label: "Walkin", value: "Walkin" },
                                                                            ]}
                                                                        />
                                                                    </Col>
                                                                )}
                                                            </>
                                                        )}

                                                        {(figures.typeSelection === "company") && (
                                                            <Col>
                                                                <CMSFormSelect
                                                                    name="checkin"
                                                                    onChange={(e) => {
                                                                        form.handleChange(e);
                                                                        form.handleSubmit();
                                                                        setFigures((prevState) => ({
                                                                            ...prevState,
                                                                            peopleGroupSelection:
                                                                                figures.peopleGroupSelection,
                                                                            graphPartSelection:
                                                                                e.target.value === ""
                                                                                    ? undefined
                                                                                    : e.target.value,
                                                                            companyListFilters: {
                                                                                checkin: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    placeholder={{ label: "All", value: "" }}
                                                                    form={form}
                                                                    options={[
                                                                        { label: "Shows", value: "Shows-Accounts" },
                                                                        {
                                                                            label: "No-Shows",
                                                                            value: "No-Shows-Accounts",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Col>
                                                        )}
                                                        {(figures.typeSelection === "country") && (
                                                            <Col>
                                                                <CMSFormSelect
                                                                    name="checkin"
                                                                    onChange={(e) => {
                                                                        form.handleChange(e);
                                                                        form.handleSubmit();
                                                                        setFigures((prevState) => ({
                                                                            ...prevState,
                                                                            peopleGroupSelection:
                                                                                figures.peopleGroupSelection,
                                                                            graphPartSelection:
                                                                                e.target.value === ""
                                                                                    ? undefined
                                                                                    : e.target.value,
                                                                            countryListFilters: {
                                                                                checkin: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    placeholder={{ label: "All", value: "" }}
                                                                    form={form}
                                                                    options={[
                                                                        { label: "Shows", value: "Shows-Accounts" },
                                                                        {
                                                                            label: "No-Shows",
                                                                            value: "No-Shows-Accounts",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                        <div className="d-flex">
                                            {checkPermission("downloadReports") && !layout.showFigureListViewFullScreen && filteredAttendees &&
                                                filteredAttendees.length > 0 && figures.typeSelection != "company" && figures.typeSelection != "country" && (
                                                    // <div onClick={() => handleDownload()}>
                                                    //     <i
                                                    //         className="fa fa-download cursor-pointer"
                                                    //         aria-hidden="true"
                                                    //         title="Download Attendees list"
                                                    //     ></i>
                                                    // </div>
                                                    <Download normal={() => handleDownload()} />
                                                )}

                                            <div
                                                className="ms-2 showOnMobile"
                                                onClick={() =>
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        chartOnMobile: true,
                                                    }))
                                                }
                                            >
                                                <i
                                                    className="icon icon-figures"
                                                    aria-hidden="true"
                                                    title="view chart"
                                                ></i>
                                            </div>
                                        </div>
                                        {layout.showFigureListViewFullScreen && (
                                            <div
                                                onClick={() =>
                                                    setLayout((prevState) => ({
                                                        ...prevState,
                                                        showFigureListViewFullScreen: false,
                                                    }))
                                                }
                                            >
                                                <i
                                                    className="fa fa-times text-primaryColor cursor-pointer"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        )}
                                    </> : (activeCompany === "" || activeCountry === "") ? <div className="d-flex align-items-center"><p className="text-white mb-0 me-2 cursor-pointer" onClick={(e) => { setActiveCompany(""); setActiveCountry("") }}>{activeCompany === "" ? "Countries" : "Account"}</p> {">"} <p className="text-white ms-2 mb-0 " >{activeCompany === "" ? activeCountry : activeCompany}</p></div>
                                        : null}



                            </div>
                        </Col>
                    )}
                </Row>
            )}
            {figures.typeSelection != "company" && figures.typeSelection != "country" && filteredAttendees &&
                filteredAttendees.length > 0 && (
                    <Row className="g-0">
                        <Col>
                        {console.log(figures)}
                      
                            <div className="sorting">
                                <div className="allSortings">
                                    <a
                                        className={`${figures.listSort === "name+" || figures.listSort === "name-"
                                            ? "active"
                                            : ""
                                            } sortItem`}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                listSort:
                                                    figures.listSort === "name+" ? "name-" : "name+",
                                            }));
                                        }}
                                    >
                                        Name{" "}
                                        {figures.listSort === "name+" ? (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        ) : figures.listSort === "name-" ? (
                                            <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        )}
                                    </a>
                                    <a
                                        className={`${figures.listSort === "company+" ||
                                            figures.listSort === "company-"
                                            ? "active"
                                            : ""
                                            } sortItem`}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                listSort:
                                                    figures.listSort === "company+"
                                                        ? "company-"
                                                        : "company+",
                                            }));
                                        }}
                                    >
                                        Company{" "}
                                        {figures.listSort === "company+" ? (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        ) : figures.listSort === "company-" ? (
                                            <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        )}
                                    </a>
                                    <a
                                        className={`${figures.listSort === "checkin+" ||
                                            figures.listSort === "checkin-"
                                            ? "active"
                                            : ""
                                            } sortItem`}
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                listSort:
                                                    figures.listSort === "checkin+"
                                                        ? "checkin-"
                                                        : "checkin+",
                                            }));
                                        }}
                                    >
                                        Check-in Time{" "}
                                        {figures.listSort === "checkin+" ? (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        ) : figures.listSort === "checkin-" ? (
                                            <i className="fa fa-chevron-up" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                        )}
                                    </a>
                                    <a
                                        className="sortItem"
                                        onClick={() => {
                                            setFigures((prevState) => ({
                                                ...prevState,
                                                listSort: undefined,
                                            }));
                                        }}
                                    >
                                        Clear <i className="fa fa-refresh" aria-hidden="true"></i>
                                    </a>
                                </div>
                                {checkPermission("viewReportsInFullScreen") && !layout.showFigureListViewFullScreen && (
                                    <div
                                        className="viewFullScreen hideOnMobile"
                                        onClick={() =>
                                            setLayout((prevState) => ({
                                                ...prevState,
                                                showFigureListViewFullScreen: true,
                                            }))
                                        }
                                    >
                                        <GiExpand className="pinkHeaderIcon" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}

            {figures.typeSelection != "company" && figures.typeSelection != "country" && (
                <CustomScrollbars style={{ height: `calc(100vh - 335px)` }}>
                    <Row className="g-0">
                        <Col className="figureListHolder">

                            {filteredAttendees &&
                                filteredAttendees.length > 0 &&
                                arraySortByProperty(filteredAttendees, figures.listSort).map(
                                    (item, i) => {
                                        return (
                                            <div
                                                className={`${item.companions > 0 ? "pb-2" : ""
                                                    } attendeeListItemTop`}
                                            >
                                                <div className="attendeeListItem2">
                                                    <div className="checkBoxContainer noClick">
                                                        <div
                                                            className={`checkBox ${item.registrationType === "Registered"
                                                                ? "red"
                                                                : ""
                                                                } ${getCheckinValueByDayBreakout(item._id, figures.activeDay, figures.activeBreakout) ? "active" : ""}`}
                                                        >
                                                            <i className="fa fa-check"></i>
                                                        </div>
                                                        <div
                                                            className="peopleGroup"
                                                            style={{
                                                                color: getColorOfPeopleGroup(
                                                                    "background",
                                                                    item.peopleGroup
                                                                ),
                                                            }}
                                                        >
                                                            {item.peopleGroup}
                                                        </div>
                                                    </div>
                                                    <div className="details">
                                                        <p className="name"> {item.fullName}  {item.nameArabic && (`(${item.nameArabic})`)}</p>

                                                        <p className="designation">{item.designation}</p>

                                                        <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                                                    </div>
                                                    {item.checkinTime && (
                                                        <div className="action_container">
                                                            <i
                                                                aria-hidden="true"
                                                                className="fa fa-clock-o clock"
                                                            ></i>{" "}
                                                            Check-in Time {item.checkinTime}
                                                        </div>
                                                    )}
                                                </div>

                                                {item.infoReq === "Yes" && (
                                                    <div className="infoMissingRow">
                                                        <p className="infoMissing">
                                                            <i
                                                                className="fa fa-exclamation-triangle"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                            Information Required
                                                        </p>
                                                    </div>
                                                )}
                                                {item.customMessage && (
                                                    <div className="infoMissingRow cursor-pointer" title="Remove custom flag" >
                                                        <i className="fa fa-exclamation-circle  me-1" aria-hidden="true"></i>
                                                        {item.customMessage}
                                                    </div>
                                                )}
                                                {item.companions > 0 && (
                                                    <div className="infoMissingRow companion">
                                                        <p className="infoMissing">
                                                            <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                                            {item.companions} Companion(s)
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            {filteredAttendees && filteredAttendees.length <= 0 && (
                                <p className="text-center py-5">No Items Found!</p>
                            )}
                        </Col>
                    </Row>
                </CustomScrollbars>
            )}
            {figures.typeSelection === "company" && activeCompany != "" && <>
                <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
                    <Row className="g-0 mt-3">
                        <Col className="figureListHolder">
                            {getListBasedOnCompany(activeCompany) &&
                                getListBasedOnCompany(activeCompany).length > 0 &&
                                getListBasedOnCompany(activeCompany).map(
                                    (item, i) => {
                                        return (
                                            <div
                                                className={`${item.companions > 0 ? "pb-4" : ""
                                                    } attendeeListItemTop`}
                                            >
                                                <div className="attendeeListItem2">
                                                    <div className="checkBoxContainer noClick">
                                                        <div
                                                            className={`checkBox ${item.registrationType === "Registered"
                                                                ? "red"
                                                                : ""
                                                                } ${getCheckinValueByDayBreakout(item._id, figures.activeDay, figures.activeBreakout) ? "active" : ""}`}
                                                        >
                                                            <i className="fa fa-check"></i>
                                                        </div>
                                                        <div
                                                            className="peopleGroup"
                                                            style={{
                                                                color: getColorOfPeopleGroup(
                                                                    "background",
                                                                    item.peopleGroup
                                                                ),
                                                            }}
                                                        >
                                                            {item.peopleGroup}
                                                        </div>
                                                    </div>
                                                    <div className="details">
                                                        <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                                                        <p className="designation">{item.designation}</p>

                                                        <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                                                    </div>
                                                    {item.checkinTime && (
                                                        <div className="action_container">
                                                            <i
                                                                aria-hidden="true"
                                                                className="fa fa-clock-o clock"
                                                            ></i>{" "}
                                                            Check-in Time {item.checkinTime}
                                                        </div>
                                                    )}
                                                </div>

                                                {item.infoReq === "Yes" && (
                                                    <div className="infoMissingRow">
                                                        <p className="infoMissing">
                                                            <i
                                                                className="fa fa-exclamation-triangle"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                            Information Required
                                                        </p>
                                                    </div>
                                                )}
                                                {item.companions > 0 && (
                                                    <div className="infoMissingRow companion">
                                                        <p className="infoMissing">
                                                            <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                                            {item.companions} Companion(s)
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            {getListBasedOnCompany(activeCompany) &&
                                getListBasedOnCompany(activeCompany).length <= 0 && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                        </Col>
                    </Row>
                </CustomScrollbars>
            </>}
            {figures.typeSelection === "company" && activeCompany === "" && (
                <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
                    <Row className="g-0 mt-2">
                        <Col className="figureListHolder">
                            {filteredCompanies &&
                                filteredCompanies.length > 0 &&
                                filteredCompanies.map((item, index) => {
                                    if (
                                        (figures.companyListFilters &&
                                            figures.companyListFilters.checkin === "") ||
                                        (figures.companyListFilters &&
                                            !figures.companyListFilters.checkin)
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCompany(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttended(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                                <p className="me-2 ">{item}</p>
                                                <p className="text-nowrap">
                                                    Total({getCountOfTotal(item)}){" "}
                                                    <span className="ms-3">
                                                        Attended({getCountOfAttended(item)})
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    if (
                                        figures.companyListFilters &&
                                        figures.companyListFilters.checkin === "Shows-Accounts" &&
                                        getCountOfAttended(item) > 0
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCompany(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttended(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                                 <p className="me-2 ">{item}</p>
                                                 <p className="text-nowrap">
                                                    Total({getCountOfTotal(item)}){" "}
                                                    <span className="ms-3">
                                                        Attended({getCountOfAttended(item)})
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    if (
                                        figures.companyListFilters &&
                                        figures.companyListFilters.checkin ===
                                        "No-Shows-Accounts" &&
                                        getCountOfAttended(item) <= 0
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCompany(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttended(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                                <p className="me-2">{item}</p>
                                                <p className="text-nowrap">Registered({getCountOfRegistered(item)}) </p>
                                            </div>
                                        );
                                })}


                            {((figures.companyListFilters &&
                                figures.companyListFilters.checkin === "") ||
                                (figures.companyListFilters &&
                                    !figures.companyListFilters.checkin)) && (totalAccounts <= 0) && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                            {figures.companyListFilters &&
                                figures.companyListFilters.checkin === "Shows-Accounts" && totalAccountsShows <= 0 && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                            {figures.companyListFilters &&
                                figures.companyListFilters.checkin ===
                                "No-Shows-Accounts" && (totalAccountsNoShows <= 0) && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}

                        </Col>
                    </Row>
                </CustomScrollbars>
            )}
            {figures.typeSelection === "country" && activeCountry != "" && <>
                <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
                    <Row className="g-0 mt-3">
                        <Col className="figureListHolder">
                            {getListBasedOnCountry(activeCountry) &&
                                getListBasedOnCountry(activeCountry).length > 0 &&
                                getListBasedOnCountry(activeCountry).map(
                                    (item, i) => {
                                        return (
                                            <div
                                                className={`${item.companions > 0 ? "pb-4" : ""
                                                    } attendeeListItemTop`}
                                            >
                                                <div className="attendeeListItem2">
                                                    <div className="checkBoxContainer noClick">
                                                        <div
                                                            className={`checkBox ${item.registrationType === "Registered"
                                                                ? "red"
                                                                : ""
                                                                } ${getCheckinValueByDayBreakout(item._id, figures.activeDay, figures.activeBreakout) ? "active" : ""}`}
                                                        >
                                                            <i className="fa fa-check"></i>
                                                        </div>
                                                        <div
                                                            className="peopleGroup"
                                                            style={{
                                                                color: getColorOfPeopleGroup(
                                                                    "background",
                                                                    item.peopleGroup
                                                                ),
                                                            }}
                                                        >
                                                            {item.peopleGroup}
                                                        </div>
                                                    </div>
                                                    <div className="details">
                                                        <p className="name"> {item.fullName} {item.nameArabic && (`(${item.nameArabic})`)}</p>

                                                        <p className="designation">{item.designation}</p>

                                                        <p className="company">{item.company} {item.companyArabic && (`(${item.companyArabic})`)}</p>
                                                    </div>
                                                    {item.checkinTime && (
                                                        <div className="action_container">
                                                            <i
                                                                aria-hidden="true"
                                                                className="fa fa-clock-o clock"
                                                            ></i>{" "}
                                                            Check-in Time {item.checkinTime}
                                                        </div>
                                                    )}
                                                </div>

                                                {item.infoReq === "Yes" && (
                                                    <div className="infoMissingRow">
                                                        <p className="infoMissing">
                                                            <i
                                                                className="fa fa-exclamation-triangle"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                            Information Required
                                                        </p>
                                                    </div>
                                                )}
                                                {item.companions > 0 && (
                                                    <div className="infoMissingRow companion">
                                                        <p className="infoMissing">
                                                            <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                                            {item.companions} Companion(s)
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            {getListBasedOnCountry(activeCountry) &&
                                getListBasedOnCountry(activeCountry).length <= 0 && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                        </Col>
                    </Row>
                </CustomScrollbars>
            </>}
            {figures.typeSelection === "country" && activeCountry === "" && (
                <CustomScrollbars style={{ height: `calc(100vh - 255px)` }}>
                    <Row className="g-0 mt-2">
                        <Col className="figureListHolder">

                            {filteredCountries &&
                                filteredCountries.length > 0 &&
                                filteredCountries.map((item, index) => {
                                    if (
                                        (figures.countryListFilters &&
                                            figures.countryListFilters.checkin === "") ||
                                        (figures.countryListFilters &&
                                            !figures.countryListFilters.checkin)
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCountry(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttendedCountry(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                               <p className="me-2 ">{item}</p>
                                               <p className="text-nowrap">
                                                    Total({getCountOfTotalCountries(item)}){" "}
                                                    <span className="ms-3">
                                                        Attended({getCountOfAttendedCountry(item)})
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    if (
                                        figures.countryListFilters &&
                                        figures.countryListFilters.checkin === "Shows-Accounts" &&
                                        getCountOfAttendedCountry(item) > 0
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCountry(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttendedCountry(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                                <p className="me-2 ">{item}</p>
                                                <p className="text-nowrap">
                                                    Total({getCountOfTotalCountries(item)}){" "}
                                                    <span className="ms-3">
                                                        Attended({getCountOfAttendedCountry(item)})
                                                    </span>
                                                </p>
                                            </div>
                                        );
                                    if (
                                        figures.countryListFilters &&
                                        figures.countryListFilters.checkin ===
                                        "No-Shows-Accounts" &&
                                        getCountOfAttendedCountry(item) <= 0
                                    )
                                        return (
                                            <div onClick={(e) => setActiveCountry(item)}
                                                className={`companyItem cursor-pointer ${getCountOfAttendedCountry(item) > 0 ? "" : "noshow"
                                                    } `}
                                            >
                                                <p className="me-2">{item}</p>
                                                <p className="text-nowrap">Registered({getCountOfRegisteredCountry(item)}) </p>
                                            </div>
                                        );
                                })}


                            {((figures.countryListFilters &&
                                figures.countryListFilters.checkin === "") ||
                                (figures.countryListFilters &&
                                    !figures.countryListFilters.checkin)) && (totalCountries <= 0) && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                            {figures.countryListFilters &&
                                figures.countryListFilters.checkin === "Shows-Accounts" && totalCountriesShows <= 0 && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}
                            {figures.countryListFilters &&
                                figures.countryListFilters.checkin ===
                                "No-Shows-Accounts" && (totalCountriesNoShows <= 0) && (
                                    <p className="text-center py-5">No Items Found!</p>
                                )}

                        </Col>
                    </Row>
                </CustomScrollbars>
            )}
        </div>
    );
};

export default ListSection;
