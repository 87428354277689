export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}
export const handleFormatArray = (item) => {
  const array = [];
  for (let i = 0; i < item.length; i++) {
    array.push({ label: item[i] === "Owners" ? "AHEAD" : item[i], value: item[i] });
  }
  array.sort((a, b) => a.label.localeCompare(b.label));
  return array;
};
export const handleFormatArrayId = () => {
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];
  const array = [];
  for (let i = 0; i < attendees.length; i++) {
    array.push({ label: attendees[i].fullName, value: attendees[i]._id });
  }
  array.sort((a, b) => a.label.localeCompare(b.label));
  return array;
};
export const getAttendeeById = (id) => {
  const attendeesContent = JSON.parse(localStorage.getItem("attendeesContent"));
  const attendees = attendeesContent && attendeesContent.attendees ? attendeesContent.attendees : [];

  return attendees.find((attendee) => attendee._id === id);
};
export const getAttendeeInfoMissing = (item) => {
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  const fields = eventData && eventData.formFields;
  for (const field in fields) {
    if (fields[field] === "Yes" && !item[field]) {
      return true;
    }
  }
  return false;
};
export const titleOfDay = (id) => {
  const eventData = JSON.parse(localStorage.getItem("eventData"));
  function groupByDayAndTitle(data) {
    const groupedData = {};

    data.forEach(item => {
      // Check if the day already exists in the groupedData object
      if (!groupedData[item.day]) {
        groupedData[item.day] = {};
      }

      // Find or add the title to the day's group
      let titlesGroup = groupedData[item.day];
      if (!titlesGroup[item.title]) {
        titlesGroup[item.title] = [];
      }

      // Add the item to the corresponding title group
      titlesGroup[item.title].push(item);
    });

    return groupedData;
  }


  let breakoutByGroup = null;
  if (eventData) {
    if (eventData.eventBreakouts) {
      breakoutByGroup = groupByDayAndTitle(eventData.eventBreakouts)
    }
  }
  const data = (eventData && eventData.eventDays && eventData.eventDays.length > 1 || breakoutByGroup[1]) ? eventData.eventDays : [];
  if (id) {
    const details = data.find(action => action.day === parseInt(id));

    return details?.title
  }
  else {
    return undefined;
  }
};
export const titleOfBreakout = (id) => {

  const event = JSON.parse(localStorage.getItem("eventData"));
  const data = event && event.eventBreakouts ? event.eventBreakouts : [];
  if (id) {
    const details = data.find(action => action._id === id);
    return details?.sessionTitle
  }
  else {
    return undefined;
  }
};
export const setDefaultDay = () => {
  const activeDay = localStorage.getItem("activeDay");

  if (activeDay === null || activeDay === undefined || activeDay === "null" || activeDay === "undefined") {

    const event = JSON.parse(localStorage.getItem("eventData"));
    const data = event && event.eventDays ? event.eventDays : [];

    if (data) {
      const details = data.find(action => (action.day === 1));

      return details?.day
    }
  }
  else {
    return activeDay
  }

};
export const getPrintBadgeValue = (id) => {
  const event = JSON.parse(localStorage.getItem("eventData"));
  const badge = event && event.badge ? event.badge : {};
  return badge && badge.printBadge === "Yes" ? true : false;
};
export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const checkFeature = (featureName) => {
  const event = JSON.parse(localStorage.getItem("eventData"));
  const clientFeatureObj = event && event.clientPackageFeatures ? event.clientPackageFeatures : {};
  const activityFeatureObj = event && event.activityFeatures ? event.activityFeatures : {};
  const osmaFeatureObj = event && event.osmaFeatures ? event.osmaFeatures : {};
  const clientFeatureValue = clientFeatureObj[featureName];
  const activityFeatureValue = activityFeatureObj[featureName];
  const osmaFeatureValue = osmaFeatureObj[featureName];
  // Return true if either value is not "0", otherwise return false
  return clientFeatureValue !== "0" && activityFeatureValue !== "0" && osmaFeatureValue != "0 ";
}