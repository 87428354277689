import React from 'react'
import { Button } from 'react-bootstrap'
import ModalBody from '../../../../UI/shared/modal/ModalBody'
import ChangeRegistrationType from './ChangeRegistrationType'

const RegistrationTypeButton = ({ details, onSubmit, updateState, show, handleClose, handleShow }) => {
    return (
        <>
            <Button
                onClick={handleShow}
                className="btn-primary ms-3"
                disabled={updateState.isSubmitting ? true : false}
            >
                {
                    details.registrationType === "Walkin"
                        ? "Mark as Registered"
                        : "Mark as Walkin"}
            </Button>
            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                closeButton={false}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show}
                content={<ChangeRegistrationType details={details} onSubmit={onSubmit} handleClose={handleClose} />}
            />
        </>

    )
}

export default RegistrationTypeButton