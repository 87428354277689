import React, { useRef, useContext, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HomeScreenContext } from '../../../../../context/HomeScreenContext';
import { bottomSwiperItems, headerItems } from '../../../../../helper/sideHeaderHelper';
import { Col, Row } from 'react-bootstrap';
import { checkFeature } from '../../../../../helper/commonHelper';
const ModuleSwiper = () => {
    const swiperRef = useRef(null);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const swiperItems = bottomSwiperItems();
    const [loading, setLoading] = useState(false)

    const slideNext = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const slidePrev = () => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    const [showButtons, setShowButtons] = useState(false);


    useEffect(() => {
        if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;
            const totalSlides = swiper.slides.length;
            const slidesPerView = swiper.params.slidesPerView;

            // Determine if navigation buttons should be shown
            setShowButtons(totalSlides > slidesPerView);
        }
    }, [layout.leftSideMenu]); // Empty dependency array means this effect runs once after the initial render
    useEffect(() => {

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, [layout.leftSideMenu]);
    return (
        <>
            {loading ? <div className="text-center moduleLoading">
                <div className="loader02"></div>
            </div> :
                <>
                    <Row className="showOnMobileSwiper showOnMobile mx-auto">
                        {swiperItems && swiperItems.filter(item => item.status === "1" && item.show).filter(layout.swiperDisplayMode === "Basic" ? (items) => items.isAllowedInBasic === "1" : () => true).map((swiperItem, index) => {
                            return (
                                <Col xs={4} c className={layout.activePage === swiperItem.pageName ? "active swiperSlider" : " swiperSlider"} key={swiperItem.name} onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        activePage: swiperItem.pageName,
                                        activePageItems: headerItems(swiperItem.pageName)
                                    }))
                                    if (swiperItem.pageName === "notes") {
                                        setLayout((prevState) => ({
                                            ...prevState,
                                            noteType: "Activity Notes"
                                        }))
                                    }
                                }}>
                                    <i className={`${swiperItem.icon} iconSwiper `} ></i>
                                    <p>{swiperItem.name ==="usefulDocuments"? "Useful Documents":swiperItem.name ==="mediaGallery"?"Media Gallery":swiperItem.name }</p>
                                </Col>
                            )
                        })}
                    </Row>
                    <div className="showOnHalfTablet">
                        {swiperItems && swiperItems.filter(item => item.status === "1" && item.show).filter(layout.swiperDisplayMode === "Basic" ? (items) => items.isAllowedInBasic === "1" : () => true).map((swiperItem, index) => {
                            return (
                                <div className={layout.activePage === swiperItem.pageName ? "active swiperSlider" : " swiperSlider"} key={swiperItem.name} onClick={() => {
                                    setLayout((prevState) => ({
                                        ...prevState,
                                        leftSideMenu: true,
                                        activePage: swiperItem.pageName,
                                        activePageItems: headerItems(swiperItem.pageName)
                                    }))
                                    if (swiperItem.pageName === "notes") {
                                        setLayout((prevState) => ({
                                            ...prevState,
                                            noteType: "Activity Notes"
                                        }))
                                    }
                                }}>

                                    <i className={`${swiperItem.icon} iconSwiper `} ></i>
                                    <p>{swiperItem.name ==="usefulDocuments"? "Useful Documents":swiperItem.name ==="mediaGallery"?"Media Gallery":swiperItem.name }</p>
                                </div>
                            )
                        })}
                    </div>
                    {swiperItems && swiperItems.length > 0 && <>

                        <div className="moduleSwiperHolder">
                            {showButtons &&
                                <button onClick={slidePrev} className='swiperButton'><GoChevronLeft /> </button>}
                            <Swiper breakpoints={{
                                300: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: layout.leftSideMenu === true ? 3 : 6,
                                }
                            }}
                                spaceBetween={15} navigation={false} modules={[Navigation]} className="moduleSwiper" ref={swiperRef}>
                                {swiperItems && swiperItems.filter(item => item.status === "1" && item.show).filter(layout.swiperDisplayMode === "Basic" ? (items) => items.mode === "Basic" : () => true).map((swiperItem, index) => {
                                    return (
                                        <SwiperSlide className={(layout.activePage === swiperItem.pageName || swiperItem.subPages.includes(layout.activePage)) ? "active" : ""} key={swiperItem.name} onClick={() => {
                                            setLayout((prevState) => ({
                                                ...prevState,
                                                leftSideMenu: true,
                                                activePage: swiperItem.pageName,
                                                activePageItems: headerItems(swiperItem.pageName)
                                            }))
                                            if (swiperItem.pageName === "notes") {
                                                setLayout((prevState) => ({
                                                    ...prevState,
                                                    noteType: "Activity Notes",
                                                    noteAttendeeId: null,
                                                }))
                                            }

                                        }}>
                                            <i className={`${swiperItem.icon} iconSwiper `} ></i>
                                            <p>{swiperItem.name ==="usefulDocuments"? "Useful Documents":swiperItem.name ==="mediaGallery"?"Media Gallery":swiperItem.name }</p>
                                        </SwiperSlide>
                                    )
                                })}

                            </Swiper>
                            {showButtons && <button onClick={slideNext} className='swiperButton'><GoChevronRight /></button>}
                        </div>
                    </>}
                </>}
        </>

    );
};

export default ModuleSwiper;
