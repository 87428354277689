import React, { useContext, useReducer, useEffect, useRef, useState } from "react";
import TopGraySectionEmpty from "../figures/TopGraySectionEmpty";
import {
    listingInitialState,
    listingReducer,
} from "../../../../../reducers/listingReducer";
import { AuthContext } from "../../../../../context/AuthContext";
import axios from "../../../../../config/axios";
import { Col, Row } from "react-bootstrap";
import CustomScrollbars from "../../../../UI/shared/customScrollbar/CustomScrollbar";
import Loading from "../../../../UI/shared/loading/Loading";
import "react-fancybox/lib/fancybox.css";
import "./usefulDoc.scss";
import ModuleDisplayDateTime from "../../../../UI/shared/display/ModuleDisplayDateTime";
import { HomeScreenContext } from "../../../../../context/HomeScreenContext";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import CMSFormImage from "../../../../UI/shared/formFields/CMSFormImage";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { updateInitialState, updateReducer } from "../../../../../reducers/updateReducer";
import CMSFormInput from "../../../../UI/shared/formFields/CMSFormInput";
import CMSFormSelect from "../../../../UI/shared/formFields/CMSFormSelect";
import ModalBody from "../../../../UI/shared/modal/ModalBody";
import DeleteDownload from "../downloads/DeleteDownload";
import EditUsefulDocument from "./EditUsefulDocument";
import { checkPermission } from "../../../../../helper/permissionsHelper";

const UsefulDocuments = () => {
    const { userAuthState } = useContext(AuthContext);
    const { layout, setLayout } = useContext(HomeScreenContext);
    const [show, setShow] = useState({
        editModel: false,
        editDetails: null,
        deleteId: null,
        deleteShow: false
    });
    const [listingState, listingDispatch] = useReducer(
        listingReducer,
        listingInitialState
    );
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const [deleteState, deleteDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const pageRef = useRef(null);
    const scrollToForm = () => {
        if (pageRef.current) {
            pageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleClose = () => setShow((prevState) => ({
        ...prevState,
        editModel: false,
    }));
    const handleShow = (item) => setShow((prevState) => ({
        ...prevState,
        editModel: true,
        editDetails: item,
    }));
    const handleDeleteClose = () => setShow((prevState) => ({
        ...prevState,
        deleteShow: false,
    }));
    const handleDeleteShow = (id) => setShow((prevState) => ({
        ...prevState,
        deleteShow: true,
        deleteId: id
    }));
    const onSubmit = async (data, { resetForm }) => {

        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.post(`add-useful-document/${userAuthState.user && userAuthState.user.activityId}`, data);

            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });

            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });

            }, 2000);
            loadItems();
            resetForm();

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    const handleDelete = async (id) => {

        deleteDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.delete(`useful-document/${id}`);

            deleteDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            loadItems();

        } catch (error) {
            console.error("There was a problem with the delete operation:", error);
            deleteDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error,
            });
            setTimeout(() => {
                deleteDispatch({
                    type: "FETCH_SUCCESS",
                });
            }, 2000);
        }
    };
    const loadItems = async () => {
        listingDispatch({
            type: "FETCH_REQUEST",
        });
        try {
            const res = await axios.get(
                `get-useful-documents/${userAuthState.user && userAuthState.user.activityId}`
            );

            listingDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            listingDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }
    };

    useEffect(() => {
        loadItems();
        updateDispatch({
            type: "FETCH_SUCCESS",
        });
        deleteDispatch({
            type: "FETCH_SUCCESS",
        });
    }, []);

    useEffect(() => {
        Fancybox.bind('[data-fancybox="single"]', {
            Toolbar: {
                display: [
                    "zoom",
                    "close",
                    "caption"
                ],
                Caption: {
                    textColor: '#fff',
                },
            },
            Thumbs: {
                autoStart: false,
            },
            Navigator: {
                touch: false,
                arrows: false,
            },
        });
        return () => {
            Fancybox.destroy();
        };
    }, []);

    return (
        <>
            {listingState.isLoading && <Loading />}
            <TopGraySectionEmpty>

                <Row className="notesTopSection w-100 mx-auto">
                    <Col className="leftSection">

                    </Col>
                    {checkPermission("addUsefulDocuments") && listingState.data && listingState.data.item && listingState.data.item.length > 0 &&
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="rightSection p-0">
                            <div className="uploadButton" onClick={scrollToForm}>
                                <span className="me-2">Add Document</span>
                                <i className="fa fa-upload" aria-hidden="true"></i>
                            </div>
                        </Col>}
                </Row>
            </TopGraySectionEmpty>
            <CustomScrollbars style={{ height: `calc(100vh - 170px) ` }}>

                {listingState.data &&
                    listingState.data.item &&
                    listingState.data.item.length > 0 && (
                        <Row className="w-100 mx-auto mt-3">
                            {listingState.data.item.map((item, index) => (
                                <Col key={index} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="itemHolder">
                                        <div className="nameActions">
                                            <p className="name">{item.name}</p>
                                            <div className="actions">
                                                {checkPermission("editUsefulDocuments") &&

                                                    <span class="rename_file" onClick={() => { handleShow(item) }}><i class="fa fa-pencil"></i></span>} &nbsp;
                                                {checkPermission("editUsefulDocuments") &&
                                                    <span class="delete_file" onClick={() => { handleDeleteShow(item._id) }}><i class="fa fa-times"></i></span>}
                                            </div>
                                        </div>

                                        <div className="imageHolder">
                                            <div className="fancyBoxHolder">
                                                <a data-fancybox="single" data-caption={item.name} href={`${process.env.REACT_APP_BACKEND_URL}/public/${item.image}`}>
                                                    <img
                                                        alt=""
                                                        src={`${process.env.REACT_APP_BACKEND_URL}/public/${item.image}`}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="dateHolder">
                                            <div className="date">
                                                <ModuleDisplayDateTime value={item.createdAt} />
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    )}
                <Row className="addNoteForm w-100 mx-auto" ref={pageRef}>
                    {updateState.isSubmitted ? <Col className="px-0 my-5">
                        <div className="successMessage">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                            <h2>Document Added Successfully</h2>

                        </div>
                    </Col> :
                        <>{checkPermission("addUsefulDocuments") &&
                            <Col className="px-0">
                                <div className="uploadButton d-flex align-content-center justify-content-start">
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                    <span className="ms-3">Add Document</span>

                                </div>
                                <div>

                                    <Formik
                                        initialValues={{
                                            activityId: userAuthState.user && userAuthState.user.activityId,
                                            image: "",
                                            name: "",
                                            sortOrder: "99",
                                            status: "1",
                                        }}
                                        validationSchema={yup.object({
                                            activityId: yup.string().required("Required"),
                                            name: yup.string().required("Required"),
                                            sortOrder: yup.string().required("Required"),
                                            status: yup.string().required("Required"),
                                            image: yup
                                                .mixed()
                                                .required("Required"),
                                        })}
                                        enableReinitialize={true}
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        onSubmit={(values, { resetForm }) => {


                                            const formData = new FormData();
                                            for (let name in values) {
                                                if (Array.isArray(values[name])) {
                                                    formData.append(
                                                        name,
                                                        JSON.stringify(values[name])
                                                    );
                                                } else {
                                                    formData.append(name, values[name]);
                                                }
                                            }

                                            onSubmit(formData, { resetForm });
                                        }}
                                    >
                                        {(form) => (
                                            <Form onSubmit={form.handleSubmit}>
                                                <Row
                                                    className={`notesFormRow ${updateState.isLoading ? "loading" : ""} mx-auto w-100`}
                                                >

                                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                        <CMSFormImage
                                                            name="image"
                                                            required={true}
                                                            form={form}
                                                        />
                                                    </Col>

                                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                        <CMSFormInput
                                                            name="name"
                                                            placeholder="Name"
                                                            required={true}
                                                            form={form}
                                                        />
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                        <CMSFormInput
                                                            name="sortOrder"
                                                            placeholder="Sort Order"
                                                            form={form}
                                                        />
                                                    </Col>
                                                    <Col xl={6} lg={6} md={6} xs={12} className="pb-4">
                                                        <CMSFormSelect
                                                            name="status"
                                                            placeholder={{ label: "Select Status", value: "" }}
                                                            form={form}
                                                            required={true}
                                                            onChange={form.handleChange}
                                                            options={[
                                                                { label: "Enabled", value: "1" },
                                                                { label: "Disabled", value: "0" },
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="mt-3 mb-3 d-flex align-items-center justify-content-start"
                                                    >

                                                        <button
                                                            className="formSubmissionButton dark "
                                                            type="submit"
                                                            onClick={(e) => {
                                                                form.handleSubmit(e);
                                                            }}
                                                        >
                                                            SAVE
                                                        </button>
                                                    </Col>

                                                    <Col
                                                        xl={12}
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        className="mb-0 d-flex align-items-center justify-content-center  errorMessageRed"
                                                    >
                                                        {!updateState.isLoading &&
                                                            updateState.status === "submitFailed" && (
                                                                <p className="mb-0">
                                                                    <i
                                                                        className="fa fa-exclamation-circle me-2"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    Action Failed! Try again.
                                                                </p>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>}
                        </>}
                </Row>
            </CustomScrollbars>
            <ModalBody
                handleClose={handleDeleteClose}
                handleShow={handleDeleteShow}
                heading="Confirm!"
                className="undoCheckInModal"
                show={show.deleteShow}
                CloseButton={true}
                content={<DeleteDownload deleteId={show.deleteId} deleteDownload={handleDelete} handleClose={handleDeleteClose} />}
            />
            <ModalBody
                handleClose={handleClose}
                handleShow={handleShow}
                heading="Edit Document"
                show={show.editModel}
                CloseButton={true}
                content={<EditUsefulDocument details={show.editDetails} handleClose={handleClose} loadItems={loadItems} />}
            />
        </>
    );
};

export default UsefulDocuments;
